import React, { useEffect, useState } from 'react';
import styles from './matchmakingTab.module.css';
import { Tooltip } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CompareMatch from './components/CompareMatch/CompareMatch';
import PossibleMatchCard from './components/PossibleMatchCard/PossibleMatchCard';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FoundItem,
  Inquiry,
  MatchRecord
} from '../../../../types/supabase/collections';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import Page from '../../../Page';
import Api from '../../../../servieces/Api/Api';
import { useSnackbar } from '../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { LoadingSpinner } from '../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import { approveMatch } from '../../../../servieces/matchmaking/approveMatch';

export const MatchmakingTab = () => {
  const { foundItemId: foundItemIdText } = useParams();
  const foundItemId = Number(foundItemIdText);
  const navigate = useNavigate();
  const { location, organisation } = useLocationData();
  const { showSnackbar } = useSnackbar();
  const [foundItem, setFoundItem] = useState<FoundItem>();
  const [potentialMatches, setPotentialMatches] = useState<
    Array<{
      matchData: MatchRecord;
      inquiry: Inquiry;
    }>
  >([]);
  const [selectedMatch, setSelectedMatch] = useState<{
    matchData: MatchRecord;
    inquiry: Inquiry;
  } | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!foundItemId || !location) return;

    setIsLoading(true);
    Api.get
      .matchmakingData({ foundItemId })
      .then((response) => {
        if (response && response.potentialMatches.length > 0) {
          setFoundItem(response.foundItem);
          setPotentialMatches(response.potentialMatches);
          setSelectedMatch(response.potentialMatches[0]);
          setIsLoading(false);
        } else {
          showSnackbar('Kunne ikke finde nogen matches', 'error');
          console.error(
            'potentialMatches amount',
            response?.potentialMatches.length
          );
          navigate(Page.l.foundItemsPossibleMatches(location.id));
        }
      })
      .catch(() => {
        showSnackbar('Kunne ikke finde nogen matches', 'error');
        navigate(Page.l.foundItemsPossibleMatches(location.id));
      });
  }, [location, foundItemId]);

  useEffect(() => {
    if (selectedMatch === null) return; // so we don't react to the initialPotential matches, before they are fetched
    potentialMatches.length > 0
      ? setSelectedMatch(potentialMatches[0])
      : navigate(Page.l.foundItemsPossibleMatches(location!.id));
  }, [potentialMatches]);

  if (isLoading || !selectedMatch || !foundItem)
    return <LoadingSpinner centered />;
  if (!location) return null;

  const onDeclineClick = (e: any) => {
    e.preventDefault();
    Api.put
      .match(selectedMatch.matchData.id, {
        match_rejected: true,
        confirmed_or_rejected_at: new Date().toISOString()
      })
      .then(() => {
        setPotentialMatches((prevState) =>
          prevState.filter(
            (potentialMatch) =>
              potentialMatch.matchData.id !== selectedMatch.matchData.id
          )
        );
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'));
  };

  const onApproveClick = async (e: any) => {
    e.preventDefault();
    approveMatch({
      selectedMatch,
      foundItem,
      location,
      organisation
    })
      .then(() => {
        showSnackbar('Match bekræftet - email sendes til ejer');
        navigate(Page.l.foundItemsPossibleMatches(location!.id));
      })
      .catch((error) => {
        console.error('MatchmakingTab error', error);
        showSnackbar('Der skete en fejl', 'error');
      });
  };

  return (
    <div className={styles.tabContainer}>
      <div className={styles.matchOptions__container}>
        {potentialMatches.map((match) => {
          const probability = match.matchData.probability;
          const isSelected = match.inquiry.id === selectedMatch.inquiry.id;

          return (
            <PossibleMatchCard
              key={match.inquiry.id}
              match={match}
              probability={probability ?? 0}
              isSelected={isSelected}
              setSelectedMatch={setSelectedMatch}
            />
          );
        })}
      </div>
      <div className={styles.compareMatch__container}>
        <div className={styles.buttons__container}>
          <Tooltip title="Afslå match">
            <div
              className={`${styles.button} ${styles.button__decline}`}
              onClick={onDeclineClick}
            >
              <CloseRoundedIcon />
            </div>
          </Tooltip>
          <Tooltip title="Bekræft match og send email til ejer">
            <div
              className={`${styles.button} ${styles.button__approve}`}
              onClick={onApproveClick}
            >
              <CheckRoundedIcon />
            </div>
          </Tooltip>
        </div>
        <CompareMatch
          foundItem={foundItem}
          selectedMatch={selectedMatch.inquiry}
          probability={selectedMatch.matchData.probability ?? 0}
        />
      </div>
    </div>
  );
};
