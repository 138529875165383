import { ReactComponent as ToyIcon } from '../../assets/logos/toyIcon.svg';
import { ReactComponent as CardIcon } from '../../assets/logos/cardIcon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/logos/phoneIcon.svg';
import { ReactComponent as KeyIcon } from '../../assets/logos/keyIcon.svg';
import { ReactComponent as JeweleryIcon } from '../../assets/logos/jewlery.svg';
import { ReactComponent as BagPackIcon } from '../../assets/logos/bagpackIcon.svg';
import { ReactComponent as GlassesIcon } from '../../assets/logos/glassesIcon.svg';
import { ReactComponent as ClothesIcon } from '../../assets/logos/clothesIcon.svg';
import { ReactComponent as HeadPhonesIcon } from '../../assets/logos/headPhonesIcon.svg';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { FC } from 'react';

interface Props {
  categoryId: number;
}
export const CategoryIcon: FC<Props> = ({ categoryId }) => {
  const props = {
    style: { maxWidth: 18, maxHeight: 18 }
  };

  switch (categoryId) {
    case 1:
      return <CategoryOutlinedIcon {...props} />;
    case 2:
      return <CardIcon {...props} />;
    case 3:
      return <KeyIcon {...props} />;
    case 4:
      return <JeweleryIcon {...props} />;
    case 5:
      return <BagPackIcon {...props} />;
    case 6:
      return <GlassesIcon {...props} />;
    case 7:
      return <ClothesIcon {...props} />;
    case 8:
      return <PhoneIcon {...props} />;
    case 9:
      return <HeadPhonesIcon {...props} />;
    case 10:
      return <ToyIcon {...props} />;
    default:
      return null;
  }
};
