import React, { FC, useEffect, useState } from 'react';
import styles from './uploadItemLoading.module.css';
import { PostFoundItemPayloadData } from './PostFoundItemJobsContext';
import { LoadingSpinner } from '../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

export type SnackbarType = 'regular' | 'success' | 'error' | 'warning';

interface Props {
  uploadingItems: Array<{
    imageFile: File;
    data: Omit<PostFoundItemPayloadData, 'posted_by_user_id' | 'location_id'>;
  }>;
}

export const UploadItemsLoading: FC<Props> = ({ uploadingItems }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCompleteIcon, setShowCompleteIcon] = useState<boolean>(false);

  useEffect(() => {
    if (uploadingItems.length === 0 && isLoading) {
      setIsLoading(false);
      setShowCompleteIcon(true);

      setTimeout(() => {
        setShowCompleteIcon(false);
      }, 1000);
    } else {
      setIsLoading(true);
    }
  }, [uploadingItems]);

  if (!showCompleteIcon && !isLoading) return null;

  return (
    <div className={styles.container}>
      <div className={styles.cardsContainer}>
        {showCompleteIcon && (
          <div
            className={styles.card}
            style={{
              bottom: 0,
              right: 0
            }}
          >
            <div className={styles.completeIcon}>
              <CheckCircleOutlinedIcon />
            </div>
          </div>
        )}

        {uploadingItems.map((item, index) => (
          <div
            key={index}
            className={styles.card}
            style={{
              zIndex: 0 - index,
              bottom: `${index * 15}px`, // Align cards from bottom
              right: `${index * 15}px` // Offset cards from right
            }}
          >
            <img
              src={URL.createObjectURL(item.imageFile)}
              alt="Uploaded preview"
              className={styles.image}
            />
            {index === 0 && (
              <div className={styles.spinner}>
                <LoadingSpinner />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
