import React, { useState, useEffect } from 'react';
import styles from './emailTemplatesTab.module.css';
import EmailTypeOptions, {
  getInquiryEmailTypeInfo
} from './components/EmailTypeOptions/EmailTypeOptions';
import {
  InquiryEmailTemplate,
  InquiryEmailType
} from '../../../../types/supabase/collections';
import { Switch } from '../../../../sharedPacakge/components/Switch/Switch';
import { TextField } from '@mui/material';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../servieces/Api/Api';
import { Button } from '../../../../sharedPacakge/components/Button/Button';
import { objectsAreEqual } from '../../../../utils/arraysAreEqual';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import { formatEmailContentWithVariables } from '../../../../sharedPacakge/emails/formatEmailContentWithVariables';
import { Modal } from '../../../../sharedPacakge/components/Modal/Modal';
import { EmailContentEditor } from './components/EmailContentEditor/EmailContentEditor';
import { useSnackbar } from '../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { sendTestInquiryEmail } from './sendTestInquiryEmail';
import { useAuth } from '../../../../contexts/AuthContext/AuthContext';

export const EmailTemplatesTab: React.FC = () => {
  const { showSnackbar } = useSnackbar();
  const { organisation, location } = useLocationData();
  const { user } = useAuth();
  const [selectedEmailType, setSelectedEmailType] =
    useState<InquiryEmailType>('INQUIRY_CREATED');
  const [originalData, setOriginalData] = useState<Omit<
    InquiryEmailTemplate,
    'id'
  > | null>(null);
  const [emailTemplate, setEmailTemplate] = useState<Omit<
    InquiryEmailTemplate,
    'id'
  > | null>(null);
  console.log('emailTemplate', emailTemplate);
  const [editContentModalIsOpen, setEditContentModalIsOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (!organisation) return;
    Api.get
      .emailTemplate(selectedEmailType, organisation.id)
      .then((response) => {
        setEmailTemplate(response);
        setOriginalData(response);
      });
  }, [selectedEmailType, organisation]);

  if (!emailTemplate || !location || !user) return null;

  const info = getInquiryEmailTypeInfo(selectedEmailType);

  const updateInquiryEmailTemplate = <
    K extends keyof Omit<InquiryEmailTemplate, 'id'>
  >(
    key: K,
    value: Omit<InquiryEmailTemplate, 'id'>[K]
  ) => {
    setEmailTemplate((prev) => (prev ? { ...prev, [key]: value } : null));
  };

  const onEditContentClick = (e: any) => {
    e.preventDefault();
    setEditContentModalIsOpen(true);
  };

  const onSave = (e: any) => {
    e.preventDefault();
    Api.upsert
      .inquiryEmailTemplate(emailTemplate)
      .then(() => {
        showSnackbar('Gemt');
        setOriginalData(emailTemplate);
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'));
  };

  const sendTestEmail = async (e: any) => {
    e.preventDefault();
    if (!user.email)
      return showSnackbar(
        'Der er ingen email er tilkoblet din bruger',
        'error'
      );

    await sendTestInquiryEmail(emailTemplate, user.email, location.name);
    showSnackbar('en test email er blevet sendt til din email');
  };

  return (
    <div className={styles.tabContainer}>
      <EmailTypeOptions
        selectedEmailType={selectedEmailType}
        onChange={(emailType) => setSelectedEmailType(emailType)}
      />
      <form className={styles.editContainer}>
        <div style={{ marginBottom: 25 }}>
          <div className={styles.emailType__title}>{info.name}</div>

          <div className={styles.emailType__description}>
            {info.description}
          </div>
        </div>
        <div className={styles.switchContainer}>
          <label htmlFor="inquiry-email-enabled">Benyt denne email form</label>
          <Switch
            uniqueId="inquiry-email-enabled"
            state={emailTemplate.enabled}
            onChange={() =>
              updateInquiryEmailTemplate('enabled', !emailTemplate.enabled)
            }
          />
        </div>
        {emailTemplate.scheduled_in_x_days !== null && (
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            Afsendes
            <TextField
              style={{ width: 100 }}
              label="dage"
              disabled={!emailTemplate.enabled}
              type="number"
              value={emailTemplate.scheduled_in_x_days}
              onChange={(e: any) =>
                updateInquiryEmailTemplate(
                  'scheduled_in_x_days',
                  e.target.value
                )
              }
              fullWidth
              variant="outlined"
            />
            {info.scheduleDescription}
          </div>
        )}
        <TextField
          label="Emne"
          disabled={!emailTemplate.enabled}
          type="text"
          value={emailTemplate.subject}
          onChange={(e: any) =>
            updateInquiryEmailTemplate('subject', e.target.value)
          }
          fullWidth
          variant="outlined"
        />
        <div>
          {emailTemplate.enabled && (
            <div
              className={styles.emailContent__edit}
              onClick={onEditContentClick}
            >
              <EditIcon />
              Rediger besked
            </div>
          )}
          <div
            className={styles.emailContent__preview}
            style={
              emailTemplate.enabled
                ? {}
                : {
                    border: emailTemplate.enabled ? 'none' : '1px solid #BDBDBD'
                  }
            }
            dangerouslySetInnerHTML={{
              __html: formatEmailContentWithVariables({
                content: emailTemplate.content,
                recipientName: 'Lars larsen',
                locationName: location.name,
                inquiryCategoryName: 'Telefon',
                inquiryDescription:
                  'Jeg har mistet min Iphone 15 Pro max, med gennemsigtig apple cover'
              })
            }}
          />
        </div>

        <div className={styles.actions__container}>
          <Button fullWidth buttonType={'secondary'} onClick={sendTestEmail}>
            <SendIcon />
            Send test email
          </Button>
          <Button
            onClick={onSave}
            fullWidth
            disabled={objectsAreEqual(originalData, emailTemplate)}
          >
            Save
          </Button>
        </div>
      </form>
      {editContentModalIsOpen && (
        <Modal
          title="Rediger email besked"
          setIsOpen={setEditContentModalIsOpen}
          className={styles.modal}
        >
          <EmailContentEditor
            value={{
              html: emailTemplate.content,
              json: emailTemplate.content_json
            }}
            onComplete={(newValue) => {
              updateInquiryEmailTemplate('content', newValue.html);
              updateInquiryEmailTemplate('content_json', newValue.json);
              setEditContentModalIsOpen(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
};
