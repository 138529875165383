import React, { FC } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import useFilters from '../../../../hooks/useFilters';
import styles from './createdAtFilter.module.css';
import { MuiLocalizationAndThemeProvider } from '../../../../../../sharedPacakge/components/MuiLocalizationAndThenmeProvider/MuiLocalizationAndThemeProvider';
import { Text } from '../../../../../../components/Text/Text';
import { Gap } from '../../../../../../components/Gap/Gap';
import { Button, Box } from '@mui/material';

interface Props {}

export const CreatedAtFilter: FC<Props> = () => {
  const [filters, updateFilter] = useFilters();

  // Handle date changes for createdAtFrom and createdAtTo
  const handleDateChange =
    (field: 'createdAtFrom' | 'createdAtTo') => (newValue: any) => {
      updateFilter({
        [field]: newValue ? dayjs(newValue).format('YYYY-MM-DD') : undefined
      });
    };

  // Handle Reset and Yesterday buttons
  const handlePresetOption = (
    field: 'createdAtFrom' | 'createdAtTo',
    option: 'reset' | 'yesterday'
  ) => {
    if (option === 'reset') {
      updateFilter({ [field]: undefined });
    } else if (option === 'yesterday') {
      const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
      updateFilter({ [field]: yesterday });
    }
  };

  return (
    <div>
      <Text type={'caption'}>oprettet mellem</Text>
      <Gap size={8} />
      <div className={styles.createdAtFilter}>
        <MuiLocalizationAndThemeProvider>
          <Box display="flex" flexDirection="column" alignItems="start" gap={1}>
            <DatePicker
              label="Fra"
              onChange={handleDateChange('createdAtFrom')}
              value={
                filters.createdAtFrom ? dayjs(filters.createdAtFrom) : null
              }
              maxDate={dayjs(new Date())}
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="start"
              gap={1}
            >
              {filters.createdAtFrom && (
                <Button
                  size="small"
                  variant="text"
                  onClick={() => handlePresetOption('createdAtFrom', 'reset')}
                >
                  Reset
                </Button>
              )}
              {!filters.createdAtFrom && (
                <Button
                  size="small"
                  variant="text"
                  onClick={() =>
                    handlePresetOption('createdAtFrom', 'yesterday')
                  }
                >
                  Siden igår
                </Button>
              )}
            </Box>
          </Box>

          <Gap size={8} />

          <Box display="flex" flexDirection="column" alignItems="start" gap={2}>
            <DatePicker
              label="Til"
              onChange={handleDateChange('createdAtTo')}
              value={filters.createdAtTo ? dayjs(filters.createdAtTo) : null}
              maxDate={dayjs(new Date())}
            />
            {filters.createdAtTo && (
              <Button
                size="small"
                variant="text"
                onClick={() => handlePresetOption('createdAtTo', 'reset')}
              >
                Reset
              </Button>
            )}
          </Box>
        </MuiLocalizationAndThemeProvider>
      </div>
    </div>
  );
};
