import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

// Define the Filters type with the createdAt filter
export interface Filters {
  query: string;
  category: number | null;
  filterItems: 'matches' | 'pickup' | 'expired' | 'archived';
  viewItem: number | null;
  ids: number[];
  createdAtFrom?: string; // Start date for filtering
  createdAtTo?: string; // End date for filtering
}

// Function to parse search parameters from URL
const parseSearchParams = (searchParams: URLSearchParams): Partial<Filters> => {
  const params: Partial<Filters> = {};

  if (searchParams.has('query')) {
    params.query = searchParams.get('query') as string;
  }
  if (searchParams.has('filter-items')) {
    params.filterItems = searchParams.get(
      'filter-items'
    ) as Filters['filterItems'];
  }
  if (searchParams.has('view-item')) {
    params.viewItem = parseInt(searchParams.get('view-item') as string, 10);
  }
  if (searchParams.has('category')) {
    params.category = parseInt(searchParams.get('category') as string, 10);
  }
  if (searchParams.has('ids')) {
    params.ids =
      searchParams
        .get('ids')
        ?.split(',')
        .map((id) => parseInt(id, 10)) || [];
  }

  // Parse createdAtFrom and createdAtTo filters
  if (searchParams.has('created-at-from')) {
    params.createdAtFrom = searchParams.get('created-at-from') as string;
  }
  if (searchParams.has('created-at-to')) {
    params.createdAtTo = searchParams.get('created-at-to') as string;
  }

  return params;
};

// Function to serialize search parameters to URL
const serializeSearchParams = (params: Partial<Filters>): URLSearchParams => {
  const searchParams = new URLSearchParams();

  if (params.query) {
    searchParams.set('query', params.query);
  }
  if (params.filterItems) {
    searchParams.set('filter-items', params.filterItems);
  }
  if (params.viewItem) {
    searchParams.set('view-item', params.viewItem.toString());
  }
  if (params.category) {
    searchParams.set('category', params.category.toString());
  }
  if (params.ids && params.ids.length > 0) {
    searchParams.set('ids', params.ids.join(','));
  }

  // Serialize createdAtFrom and createdAtTo filters
  if (params.createdAtFrom) {
    searchParams.set('created-at-from', params.createdAtFrom);
  }
  if (params.createdAtTo) {
    searchParams.set('created-at-to', params.createdAtTo);
  }

  return searchParams;
};

// Custom hook to use and update search parameters
const useFilters = (): [
  Partial<Filters>,
  (params: Partial<Filters>) => void
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Memoize the parsed parameters to avoid recalculating on each render
  const params = useMemo(() => parseSearchParams(searchParams), [searchParams]);

  // Local state to track when we need to update search params
  const [updatePending, setUpdatePending] = useState<Partial<Filters> | null>(
    null
  );

  // Update the URL search params only when necessary
  useEffect(() => {
    if (updatePending) {
      const currentSearchParams = serializeSearchParams(params);
      const newSearchParams = serializeSearchParams(updatePending);

      // Only update if the serialized params are different
      if (currentSearchParams.toString() !== newSearchParams.toString()) {
        setSearchParams(newSearchParams);
      }

      // Clear the updatePending flag to avoid repeated updates
      setUpdatePending(null);
    }
  }, [updatePending, params, setSearchParams]);

  // Function to request an update to search params
  const updateSearchParams = (newParams: Partial<Filters>) => {
    setUpdatePending({ ...params, ...newParams });
  };

  return [params, updateSearchParams];
};

export default useFilters;
