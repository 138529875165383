import React, { FC, ReactNode } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectProps,
  InputAdornment,
  IconButton
} from '@mui/material';
import { CategoryRecord } from '../../../types/supabase/collections';
import CloseIcon from '@mui/icons-material/Close';
import { CategoryIcon } from '../CategoryIcon/CategoryIcon';

interface CustomProps {
  label: string;
  value: CategoryRecord | null;
  onChange: (e: any, newValue: CategoryRecord | null) => void;
  disabled?: boolean;
  labelIcon?: ReactNode;
  itemCategories: CategoryRecord[];
  labelClassName?: string;
  unSelectable?: boolean;
}

type Props = CustomProps &
  Omit<
    SelectProps,
    | 'renderInput'
    | 'options'
    | 'getOptionLabel'
    | 'value'
    | 'onChange'
    | 'disabled'
  >;

const CategoryDropdown: FC<Props> = ({
  label,
  value,
  itemCategories,
  onChange,
  labelIcon,
  disabled = false,
  labelClassName = '',
  unSelectable = false,
  ...selectProps
}) => {
  const handleClear = (e: any) => {
    e.preventDefault();
    onChange(e, null);
  };
  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel className={labelClassName}>
        <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          {!value && labelIcon}
          {label}
        </span>
      </InputLabel>
      <Select
        value={value?.id || ''}
        onChange={(e) => {
          const selectedCategory = itemCategories.find(
            (category) => category.id === e.target.value
          );
          onChange(e, selectedCategory || null);
        }}
        sx={{
          '.MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            gap: '6px'
          }
        }}
        label={label}
        disabled={disabled}
        IconComponent={unSelectable ? () => null : undefined}
        endAdornment={
          unSelectable &&
          value && (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClear}
                size="small"
                edge="end"
                aria-label="clear selection"
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          )
        }
        {...selectProps}
      >
        {itemCategories.map((category) => (
          <MenuItem
            key={category.id}
            value={category.id}
            style={{ display: 'flex', gap: 6, alignItems: 'center' }}
          >
            <CategoryIcon categoryId={category.id} />
            {category.name_danish}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CategoryDropdown;
