import React, { FC, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import 'dayjs/locale/da';
import styles from './newFoundItemButton.module.css';
import {
  CookieName,
  getBooleanCookie,
  setCookie
} from '../../../../../utils/cookies/cookie';
import { useLocationData } from '../../../../../contexts/LocationDataContext/LocationDataContext';
import { ItemCategory } from '../../../../../types/supabase/collections';
import { useSnackbar } from '../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { scaleAndMinifyImage } from '../../../../../sharedPacakge/utils/imageScaleMinify';
import { FormModal } from '../../../../../sharedPacakge/components/Modal/FormModal/FormModal';
import { Switch } from '../../../../../sharedPacakge/components/Switch/Switch';
import ImageUploader from '../../../../../sharedPacakge/components/ImageUploader/ImageUploader';
import CategoryDropdown from '../../../../../sharedPacakge/components/CategoryDropdown.tsx/CategoryDropdown';
import {
  OptionalNewFoundItemData,
  OptionalNewFoundItemFields
} from './components/OptionalNewFoundItemFields/OptionalNewFoundItemFields';
import { Text } from '../../../../../components/Text/Text';
import { Chip } from '../../../../../components/Chip/Chip';
import { useAuth } from '../../../../../contexts/AuthContext/AuthContext';
import { ItemStatus } from '../../../../../sharedPacakge/types/ItemStatus';
import { usePostFoundItemJobs } from '../../../../../contexts/FoundItemPosterContext/PostFoundItemJobsContext';

interface Props {
  onClose: () => void;
  uploadedImages: File[];
}

const NewFoundItemModal: FC<Props> = ({ onClose, uploadedImages }) => {
  const { showSnackbar } = useSnackbar();
  const { postFoundItem } = usePostFoundItemJobs();
  const { user } = useAuth();
  const { organisation, location, itemCategories } = useLocationData();
  const [useImageDetection, setUseImageDetection] = useState<boolean>(
    getBooleanCookie(CookieName.EnabledFoundItemCreationImageDetection) ?? true
  );
  const [isMultipleItems, setIsMultipleItems] = useState<boolean>(false);
  const [loadingImageDetection, setLoadingImageDetection] =
    useState<boolean>(false);
  const [imageFiles, setImageFiles] = useState<File[]>(uploadedImages);
  const [parentCategory, setParentCategory] = useState<ItemCategory | null>(
    null
  );
  const [category, setCategory] = useState<ItemCategory | null>(null);
  const [description, setDescription] = useState('');
  const [disableSubmitButton, setDisableSubmitButton] =
    useState<boolean>(false);
  const [isHighValueItem, setIsHighValueItem] = useState<boolean>(false);
  const [optionalFields, setOptionalFields] =
    useState<OptionalNewFoundItemData>({
      foundAtDateTime: new Date(),
      foundAtLocation: '',
      storageLocation: '',
      notes: ''
    });

  useEffect(() => {
    setCookie(
      CookieName.EnabledFoundItemCreationImageDetection,
      useImageDetection,
      Infinity
    );

    !useImageDetection && setIsMultipleItems(false);
  }, [useImageDetection]);

  useEffect(() => {
    if (!useImageDetection) {
      if (description === '' || !category) {
        setDisableSubmitButton(true);
      } else {
        setDisableSubmitButton(false);
      }
      return;
    }

    setDisableSubmitButton(false);
  }, [useImageDetection, description, category, imageFiles]);

  if (!organisation || !location) return null;

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const chosenCategory = useImageDetection
      ? itemCategories.find(({ id }) => id === 100)!
      : category;
    if (!chosenCategory)
      return showSnackbar('Kategori eller under kategori mangler', 'error');

    const foundItem = {
      storage_location: optionalFields.storageLocation,
      notes: optionalFields.notes,
      found_at_location: optionalFields.foundAtLocation,
      found_at_date: optionalFields.foundAtDateTime.toISOString(),
      used_image_detection: useImageDetection,
      location_id: location.id,
      organisation_id: organisation.id,
      posted_by_user_id: user!.id,
      status: ItemStatus.AwaitingMatch,
      ...(useImageDetection
        ? {
            category_id: 100,
            description: 'Oprettet',
            is_high_value_item: false
          }
        : {
            category_id: chosenCategory.id,
            description,
            is_high_value_item: isHighValueItem
          })
    };

    imageFiles.forEach((imageFile) => {
      postFoundItem(imageFile, {
        ...foundItem,
        imageDetection: useImageDetection,
        isMultipleItems
      });
    });

    onClose();
  };

  const onFileUpload = async (files: File[]) => {
    if (files && files.length > 0) {
      const fileArray = Array.from(files); // Convert FileList to Array
      const optimizedImages = await Promise.all(
        fileArray.map(
          (file) => scaleAndMinifyImage(file, 800, 800, 0.5) // Assumes this returns Base64 or Blob URLs
        )
      );
      setImageFiles(optimizedImages);
    }
  };

  const onNameChange = (e: any) => {
    setDescription(e.target.value);
  };

  const parentCategories = itemCategories
    .filter((category) => category.parent_category_id === null)
    .sort((a, b) =>
      a.name_danish.toLowerCase().localeCompare(b.name_danish.toLowerCase())
    );

  return (
    <FormModal
      onFormSubmit={onSubmit}
      title="Tilføj fundet genstand"
      onClose={onClose}
      submitButtonText="Udfør"
      disabled={disableSubmitButton}
    >
      <div className={styles.container}>
        <div className={styles.switches__section}>
          <div className={styles.switches__labelContainer}>
            <label htmlFor="useImageDetection-switch">
              <Text type="body" style={{ textAlign: 'right' }}>
                Benyt AI-genkendelse
              </Text>
            </label>
            <label
              htmlFor="isMultipleItems-switch"
              style={{ alignItems: 'center', textAlign: 'right' }}
            >
              <Chip background="#deba07" borderColor="#967f02">
                Ny
              </Chip>
              <Chip
                background="var(--color-primary)"
                borderColor="var(--color-primary-hover)"
              >
                Beta
              </Chip>
              <Text type="body">Genkend flere objekter fra dette billede</Text>
            </label>
          </div>
          <div className={styles.switches__container}>
            <Switch
              uniqueId={'useImageDetection-switch'}
              state={useImageDetection}
              onChange={() => setUseImageDetection((prevState) => !prevState)}
            />
            <Switch
              uniqueId={'isMultipleItems-switch'}
              state={isMultipleItems}
              onChange={() => setIsMultipleItems((prevState) => !prevState)}
            />
          </div>
        </div>

        <ImageUploader
          multiPost
          onFileUpload={onFileUpload}
          previewImage={imageFiles}
          renderText={(images) => (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
                gap: '10px',
                width: '100%',
                maxHeight: '250px',
                overflowY: 'auto'
              }}
            >
              {Array.isArray(images) &&
                images.map((image, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden',
                      backgroundColor: '#f9f9f9',
                      border: '1px solid #ddd',
                      borderRadius: '4px'
                    }}
                  >
                    <img
                      src={image}
                      alt={`Preview ${index + 1}`}
                      style={{
                        width: '100%',
                        height: 'fit-content',
                        maxHeight: '200px',
                        objectFit: 'contain'
                      }}
                    />
                  </div>
                ))}
            </div>
          )}
        />
        {!useImageDetection && (
          <>
            <CategoryDropdown
              label="Kategori"
              value={parentCategory}
              itemCategories={parentCategories}
              onChange={(e, newValue: ItemCategory | null) => {
                if (newValue) {
                  // mategerial UI will get warnings if we don't use exact object refference
                  const exactObjRef = parentCategories.find(
                    (p) => p.id === newValue.id
                  ) as ItemCategory;
                  setParentCategory(exactObjRef);
                  setCategory(null);
                } else setParentCategory(null);
              }}
            />
            {parentCategory && (
              <CategoryDropdown
                label="Under kategori"
                value={category}
                itemCategories={itemCategories
                  .filter(
                    (category) =>
                      category.parent_category_id === parentCategory.id
                  )
                  .sort((a, b) =>
                    a.name_danish
                      .toLowerCase()
                      .localeCompare(b.name_danish.toLowerCase())
                  )}
                onChange={(e, newValue: ItemCategory | null) => {
                  if (newValue) {
                    // mategerial UI will get warnings if we don't use exact object refference
                    const exactObjRef = itemCategories.find(
                      (p) => p.id === newValue.id
                    ) as ItemCategory;
                    setCategory(exactObjRef);
                  } else setCategory(null);

                  if (newValue) setIsHighValueItem(newValue.is_high_value);
                }}
              />
            )}
            <TextField
              fullWidth
              id="outlined-basic"
              label="Beskrivelse"
              variant="outlined"
              value={description}
              onChange={onNameChange}
              disabled={loadingImageDetection}
            />
            <Switch
              uniqueId={'isHighValueFoundItem-switch'}
              state={isHighValueItem}
              label="Genstand af værdi over 500kr."
              onChange={() => setIsHighValueItem((prevState) => !prevState)}
            />
          </>
        )}
        <OptionalNewFoundItemFields
          optionalFields={optionalFields}
          setOptionalFields={setOptionalFields}
        />
      </div>
    </FormModal>
  );
};

export default NewFoundItemModal;
