import React, { FC } from 'react';
import { CategoryRecord } from '../../../../types/supabase/collections';
import { CategoryIcon } from '../../../../sharedPacakge/components/CategoryIcon/CategoryIcon';
import styles from './CategoryChip.module.css';
import { Text } from '../../../../components/Text/Text';

interface Props {
  category: CategoryRecord;
  fullWidth?: boolean;
}

export const CategoryChip: FC<Props> = ({ category, fullWidth = false }) => {
  return (
    <div
      className={`${styles.container} ${!fullWidth ? styles.truncated : ''}`}
    >
      <CategoryIcon categoryId={category.parent_category_id || category.id} />
      <Text type="body" className={styles.categoryName}>
        {category.name_danish}
      </Text>
    </div>
  );
};
