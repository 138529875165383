import React, { useState } from 'react';
import logo from '../../../assets/logo/darkFindyLogo.png';
import styles from '../loginAndSignupStyles.module.css';
import { redirectAfterLogin } from '../redirectAfterLogin';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext/AuthContext';
import { SetPageTitle } from '../../../utils/SetPageTitle';
import Page from '../../Page';
import { Button } from '../../../sharedPacakge/components/Button/Button';
import { FindyLogo } from '../../LocationPage/components/FindyLogo/FindyLogo';

const Login = () => {
  SetPageTitle('Login');
  const { signInWithEmail } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');   

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (!isSigningIn) {
      setIsSigningIn(true);
      const user = await signInWithEmail(email, password);

      if (!user) {
        setIsSigningIn(false);
        return;
      }

      await redirectAfterLogin(user.id);
    }
  };

  return (
    <main className={styles.container}>
      <div className={styles.logo}>
        <FindyLogo height={50} />
      </div>
      <form className={styles.form} onSubmit={onSubmit}>
        <h1 className={styles.title}>Velkommen tilbage</h1>
        <TextField
          label="Email"
          type="text"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          fullWidth
          variant="outlined"
          disabled={isSigningIn}
        />
        <TextField
          label="Adgangskode"
          type="password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
          fullWidth
          variant="outlined"
          disabled={isSigningIn}
        />

        {/*{errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}*/}

        <Link className={styles.resetPasswordLink} to={Page.resetPassword}>
          Glemt din adgangskode?
        </Link>
        <Button disabled={isSigningIn} type="submit" fullWidth>
          Login
        </Button>
        <div className={styles.noUser__text}>
          Har du ikke en bruger?{' '}
          <Link className={styles.noUser__link} to={'/signup'}>
            Opret bruger
          </Link>
        </div>
      </form>
    </main>
  );
};

export default Login;
