import React, { FC } from 'react';
import styles from './archivedFoundItemsTable.module.css';
import PostAddIcon from '@mui/icons-material/PostAdd';
import {
  ArchivedFoundItem,
  FoundItem
} from '../../../../../../types/supabase/collections';
import FoundItemsTable from '../../../../components/FoundItemsTable/FoundItemTable';
import { ItemStatus } from '../../../../../../sharedPacakge/types/ItemStatus';
import { ViewItemModalCallbacks } from '../../../../components/ViewItemModal/ViewItemModalCallbacks';

export const ArchivedFoundItemsTable: FC<{
  foundItems: FoundItem[];
  callbacks: ViewItemModalCallbacks<FoundItem>;
}> = ({ foundItems, callbacks }) => {
  const renderNoFoundItems = () => (
    <>
      <h2 className={styles.noItems__text}>Ingen afviklet genstande endnu</h2>
      <p
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '5px'
        }}
      >
        Opret fundne genstande
        <PostAddIcon />
      </p>
    </>
  );

  return (
    <FoundItemsTable<ArchivedFoundItem>
      foundItems={foundItems as ArchivedFoundItem[]}
      callbacks={callbacks}
      options={{
        renderNoFoundItems: renderNoFoundItems,
        columns: [
          'image',
          'category',
          'description',
          'found_at_date',
          'id',
          'archive_reason',
          'status'
        ]
      }}
    />
  );
};
