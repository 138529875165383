import React, { FC, ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './drawer.module.css';
import { SetState } from '../../sharedPacakge/types/utilityTypes';

export interface ModalProps {
  children: ReactNode;
  setIsOpen: SetState<boolean>;
  className?: string;
  anchor?: 'left' | 'right';
}

export const Drawer: FC<ModalProps> = ({
  children,
  setIsOpen,
  className,
  anchor = 'left'
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const portal = document.getElementById('modal-root');

  if (!portal) {
    console.warn("Modal can't open, there is no modal root in the html");
    return null;
  }

  const clickOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    // Close modal when clicking outside the modal content
    if (e.target === e.currentTarget) {
      setIsOpen(false);
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.background} onClick={clickOutside}>
      <div
        className={`${styles.modal} ${className ?? ''}`}
        style={{
          left: anchor === 'left' ? 0 : 'auto',
          right: anchor === 'right' ? 0 : 'auto'
        }}
      >
        <div className={styles.contentContainer}>{children}</div>
      </div>
    </div>,
    portal
  );
};
