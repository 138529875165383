import React, { ReactNode, useMemo, useState } from 'react';
import styles from './FoundItemsTable.module.css';
import GenericTable, {
  Column
} from '../../../../components/GenericTable/GenericTable';
import { formatDateOnly } from '../../../../utils/formatting/formatDate';
import {
  ArchivedFoundItem,
  FoundItem
} from '../../../../types/supabase/collections';
import { LoadingSpinner } from '../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import { translateItemStatus } from '../../../../utils/translate/translateItemStatus';
import { translateArchiveReason } from '../../../../utils/translate/translateArchiveReason';
import { FoundItemArchiveReason } from '../../../../sharedPacakge/types';
import ViewFoundItemModal from '../ViewItemModal/ViewFoundItemModal/ViewFoundItemModal';
import { ViewItemModalCallbacks } from '../ViewItemModal/ViewItemModalCallbacks';
import useFilters from '../../hooks/useFilters';
import { CategoryChip } from '../CategoryChip/CategoryChip';
import { StatusCircle } from '../StatusCircle/StatusCircle';

interface Props<T> {
  isLoading?: boolean;
  foundItems: T[];
  callbacks: ViewItemModalCallbacks<T>;
  options: {
    columns: Array<keyof T>;
    onRowClick?: (e: any, item: T) => void;
    isRowLoading?: (row: T) => void;
    renderActionsColumn?: (row: T) => ReactNode;
    renderNoFoundItems?: () => ReactNode;
    customColumns?: Column<T>[];
    multiSelect?: {
      enabled: true;
      onSelectedRowChange: (selectedRow: T[]) => void;
    };
  };
}

function FoundItemsTable<T extends FoundItem | ArchivedFoundItem>({
  isLoading = false,
  foundItems,
  options,
  callbacks
}: Props<T>) {
  const [viewingItem, setViewingItem] = useState<T | null>(null);
  const [filters, udpateFilter] = useFilters();

  useMemo(() => {
    if (filters.viewItem) {
      const item = foundItems.find(
        (item) => item.id && item.id === filters.viewItem
      );
      item && setViewingItem(item);
    } else {
      setViewingItem(null);
    }
  }, [foundItems, filters]);

  const onRowClick = (e: any, item: T) => {
    e.preventDefault();
    item.id && udpateFilter({ viewItem: item.id });
  };

  if (isLoading)
    return (
      <div className={styles.loadingSpinner__container}>
        <LoadingSpinner />
      </div>
    );

  if (!isLoading && foundItems.length === 0)
    return options.renderNoFoundItems ? (
      <>{options.renderNoFoundItems()}</>
    ) : null;

  const allColumns: Column<T>[] = [
    {
      id: 'image',
      label: 'Billede',
      render: (item) => (
        <img
          className={styles.itemImage}
          src={item.image}
          alt="genstand billede"
          width={90}
          height={90}
        />
      ),
      renderMobile: true
    },
    {
      id: 'category',
      label: 'Kategori',
      render: ({ category }) => <CategoryChip category={category} />,
      renderMobile: true
    },
    {
      id: 'description',
      label: 'Beskrivelse',
      render: ({ description }) => (
        <div className={styles.description}>{description}</div>
      ),
      renderMobile: ({ description }) => (
        <div
          className={styles.description}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block'
          }}
        >
          {description}
        </div>
      )
    },
    {
      id: 'found_at_date',
      label: 'Fundet tidspunkt',
      render: ({ found_at_date }) => formatDateOnly(found_at_date)
    },
    {
      id: 'status',
      label: 'Status',
      render: (item) => (
        <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
          <StatusCircle item={item} size="small" />
          {'archive_reason' in item
            ? translateArchiveReason(
                item.archive_reason as FoundItemArchiveReason
              )
            : translateItemStatus(item.status)}
        </div>
      ),
      renderMobile: true
    },
    {
      id: 'id',
      label: 'ID',
      render: ({ id }) => <div className={styles.subtle}>{id}</div>
    }
  ];

  const columns = [
    ...allColumns.filter((column) =>
      options.columns.includes(column.id as keyof T)
    ),
    ...(options.customColumns ? options.customColumns : [])
  ];

  let props: any = {};
  if (options?.multiSelect) props.multiSelect = options?.multiSelect;
  if (options?.renderActionsColumn)
    props.actionsColumn = options?.renderActionsColumn;
  if (options?.onRowClick) props.onRowClick = options?.onRowClick;

  return (
    <div className={styles.container}>
      <GenericTable<T>
        columns={columns}
        data={foundItems}
        rowKey="id"
        onRowClick={onRowClick}
        {...props}
      />
      {viewingItem && (
        <ViewFoundItemModal callbacks={callbacks} foundItem={viewingItem} />
      )}
    </div>
  );
}

export default FoundItemsTable;
