import { Outlet as Content, useLocation, useNavigate } from 'react-router-dom';
import styles from './costumizationTab.module.css';
import Tabs from '../../../../components/Tabs/Tabs';
import React, { useEffect, useState } from 'react';
import Page from '../../../Page';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import { Gap } from '../../../../components/Gap/Gap';
import { Role } from '../../../../types/supabase/collections';
import { useSnackbar } from '../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';

const tabs = [
  {
    label: 'Generelt',
    path: '/general',
    icon: <SettingsOutlinedIcon />
  },
  {
    label: 'Brugere',
    path: '/users',
    icon: <PeopleOutlinedIcon />
  },
  {
    label: 'Emails',
    path: '/email-templates',
    icon: <MailOutlineIcon />,
    visibleForRoles: [Role.OrganisationAdmin]
  },
  {
    label: 'Efterlysnings formular',
    path: '/edit-inquiry-form',
    icon: <BrushOutlinedIcon />,
    visibleForRoles: [Role.OrganisationAdmin]
  }
];
const tabToLink = (path: string, locationId: number) => {
  switch (path) {
    case '/general':
      return Page.l.customization.general(locationId);
    case '/edit-inquiry-form':
      return Page.l.customization.editInquiryForm(locationId);
    case '/email-templates':
      return Page.l.customization.emailTemplates(locationId);
    case '/users':
      return Page.l.customization.users(locationId);
    default:
      return Page.l.customization.general(locationId);
  }
};

export const CustomizationsTab = () => {
  const pageLocation = useLocation();
  const { showSnackbar } = useSnackbar();
  const { location, userRole } = useLocationData();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState<number>(0);

  useEffect(() => {
    const currentTabIndex = tabs.findIndex((tab) =>
      pageLocation.pathname.includes(tab.path)
    );
    setTabIndex(currentTabIndex);
  }, [pageLocation]);

  if (!location || !userRole) return null;

  const tabsUserHasAccessTo = tabs.filter(
    (tab) => !tab.visibleForRoles || tab.visibleForRoles.includes(userRole)
  );

  const handleTabClick = (tabIndex: number) => {
    if (tabsUserHasAccessTo.find((tab) => tab.path === tabs[tabIndex].path)) {
      navigate(tabToLink(tabs[tabIndex].path, location!.id));
    } else {
      showSnackbar(`Du har ikke adgang til ${tabs[tabIndex].label}`, 'warning');
    }
  };

  const displayTabs = tabs.map((tab) => {
    const hasAccessToThisTab = tabsUserHasAccessTo.find(
      (accessableTab) => accessableTab.path === tab.path
    );
    console.log('FDJKALFNDIOA', tab, hasAccessToThisTab);
    return hasAccessToThisTab
      ? tab
      : { ...tab, icon: <LockPersonOutlinedIcon /> };
  });

  return (
    <div>
      <Tabs
        tabs={displayTabs}
        onTabClick={handleTabClick}
        selectedTabIndex={tabIndex}
      />
      <div>
        <Gap size={16} />
        <Content />
      </div>
    </div>
  );
};
