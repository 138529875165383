import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './button.module.css';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fullWidth?: boolean;
  buttonType?: 'primary' | 'secondary' | 'warn' | 'white' | 'quiet';
  size?: 'small' | 'normal' | 'large';
}

export const Button: FC<Props> = ({
  buttonType = 'primary',
  fullWidth,
  className,
  style,
  size = 'normal',
  ...props
}) => {
  // Use classNames to dynamically build the class list
  const buttonClass = classNames(
    styles.button,
    {
      [styles.secondary]: buttonType === 'secondary',
      [styles.quiet]: buttonType === 'quiet',
      [styles.warn]: buttonType === 'warn',
      [styles.white]: buttonType === 'white',
      [styles.size__small]: size === 'small',
      [styles.size__normal]: size === 'normal',
      [styles.size__large]: size === 'large'
    },
    className // Add any additional className passed in as a prop
  );

  const buttonProps = {
    ...props,
    className: buttonClass,
    style: { ...style, ...(fullWidth ? { width: '100%' } : {}) }
  };

  return <button {...buttonProps} />;
};
