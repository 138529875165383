import styles from './categoryFilter.module.css';
import React, { FC } from 'react';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { CategoryRecord } from '../../../../../../types/supabase/collections';
import useFilters from '../../../../hooks/useFilters';
import CategoryDropdown from '../../../../../../sharedPacakge/components/CategoryDropdown.tsx/CategoryDropdown';

interface Props {
  itemCategories: CategoryRecord[];
}
export const CategoryFilter: FC<Props> = ({ itemCategories }) => {
  const [filters, updateFilter] = useFilters();
  const onFilterChange = (e: any, newValue: CategoryRecord | null) => {
    e.preventDefault();
    updateFilter({ category: newValue ? newValue.id : null });
  };

  return (
    <CategoryDropdown
      label="Kategori"
      labelIcon={<CategoryOutlinedIcon />}
      labelClassName={styles.textColor}
      className={styles.textField}
      unSelectable
      value={itemCategories.find((c) => c.id === filters.category) || null}
      onChange={onFilterChange}
      itemCategories={itemCategories
        .filter((c) => !c.parent_category_id)
        .sort((a, b) =>
          a.name_danish.localeCompare(b.name_danish, undefined, {
            sensitivity: 'base'
          })
        )}
    />
  );
};
