import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './FoundItemsTable.module.css';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../servieces/Api/Api';
import {
  ArchivedFoundItem,
  CategoryRecord,
  FoundItem,
  ItemCategory,
  MatchRecord,
  PostFoundItemJobsRecord
} from '../../../../types/supabase/collections';
import useFilters from '../../hooks/useFilters';
import { SearchBar } from '../../../../components/SearchBar/SearchBar';
import Tabs from '../../../../components/Tabs/Tabs';
import { MatchesBatch } from './components/MatchesBadge/MatchesBatch';
import {
  filterItemsWithPossibleMatches,
  PossibleMatchesTable
} from './components/PossibleMatchesTable/PossibleMatchesTable';
import { LoadingSpinner } from '../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import {
  ExpiredOrLeftBehindTable,
  filterExpiredOrLeftBehindItems
} from './components/ExpiredOrLeftBehindTable/ExpiredOrLeftBehindTable';
import { Text } from '../../../../components/Text/Text';
import {
  AwaitingPickupTable,
  filterItemsAwaitingPickup
} from './components/AwaitingPickupTable/AwaitingPickupTable';
import { AllFoundItemsTable } from './components/AllFoundItems/FoundItemsTab';
import { ArchivedFoundItemsTable } from './components/ArchivedFoundItems/ArchivedFoundItemsTable';
import { useSnackbar } from '../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import Page from '../../../Page';
import { ItemFilters } from '../../components/ItemFilters/ItemFilters';
import { usePostFoundItemJobs } from '../../../../contexts/FoundItemPosterContext/PostFoundItemJobsContext';
import { supabase } from '../../../../servieces/supabase/supabaseClient';

const getTabs = (amountOfPossibleMatches: number) => [
  {
    label: 'Alle',
    description: 'Alle aktive genstande',
    searchParamValue: null,
    activeUnderlineColor:
      'linear-gradient(to right, var(--color-primary), var(--color-positive), var(--color-warning))'
  },
  {
    label: 'Mulige matches',
    description: 'Alle genstande der har mulige matchende efterlysning',
    searchParamValue: 'matches',
    suffix: <MatchesBatch amount={amountOfPossibleMatches} />,
    filter: filterItemsWithPossibleMatches,
    activeUnderlineColor: 'var(--color-primary)'
  },
  {
    label: 'Afhentning',
    description: 'Alle genstande der er aftalt at blive afhentet af ejeren',
    searchParamValue: 'pickup',
    filter: filterItemsAwaitingPickup,
    activeUnderlineColor: 'var(--color-positive)'
  },
  {
    label: 'Udløbet',
    description: 'Alle genstande der har lagt på lager for længe',
    searchParamValue: 'expired',
    filter: filterExpiredOrLeftBehindItems,
    activeUnderlineColor: 'var(--color-warning)'
  },
  {
    label: 'Arkiveret',
    description: 'Genstande der er arkiverede',
    searchParamValue: 'archived',
    activeUnderlineColor: 'var(--color-gray-600)'
  }
];

const FoundItemsTab: FC = () => {
  const { location, itemCategories } = useLocationData();
  const { onImageDetectionUpdateItems, onPreviewItemPosted } =
    usePostFoundItemJobs();
  const [filters, updateFilter] = useFilters();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { showSnackbar } = useSnackbar();
  const [foundItems, setFoundItems] = useState<
    FoundItem[] | ArchivedFoundItem[]
  >([]);
  const [matchesData, setMatchesData] = useState<MatchRecord[]>([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    getTabs(0).findIndex((tab) => {
      if (filters.filterItems === tab.searchParamValue) return true;
      if (!filters.filterItems && tab.searchParamValue === null) return true;
    }) as any
  );
  const isArchivedItems = useMemo(
    () => filters.filterItems === 'archived',
    [filters]
  );
  const [helperText, setHelperText] = useState('');

  const removeFoundItemLocally = (id: number) => {
    setFoundItems((prevState) => prevState.filter((item) => item.id !== id));
  };

  const fetchFoundItems = async () => {
    // todo fetch archived if isArchived is true

    if (!location) return;
    setIsLoading(true);
    const foundItemsResponse = await Api.get.foundItems({
      location_id: location.id,
      archived: isArchivedItems
    });

    foundItemsResponse && setFoundItems(foundItemsResponse);

    const matchesDataResponse = await Api.get.matches({
      customQuery: (query) =>
        query
          .eq('location_id', location.id)
          .eq('match_rejected', false)
          .eq('match_confirmed', false)
    });

    setMatchesData(matchesDataResponse);
    setIsLoading(false);
  };

  // useEventListener('foundItemUploaded', fetchFoundItems);

  useEffect(() => {
    fetchFoundItems();
  }, [location, isArchivedItems]);

  // useEffect(() => {
  //   // Step 1: Create a WebSocket connection directly to Supabase
  //   const socket = new WebSocket(
  //     'wss://ywrzofjkaquflnuqjhpp.supabase.co/realtime/v1/websocket?apikey='
  //   );
  //
  //   // Step 2: WebSocket connection open event
  //   socket.onopen = () => {
  //     console.log('Connected to Supabase WebSocket');
  //
  //     // Step 3: Subscribe to the channel for updates on the `post_found_item_jobs` table
  //     const subscribeMessage = {
  //       type: 'subscribe',
  //       topic: 'post_found_item_jobs_updates:239', // Your channel name (e.g., post_found_item_jobs_updates)
  //       payload: {
  //         event: 'UPDATE', // Listen to UPDATE events
  //         schema: 'public',
  //         table: 'post_found_item_jobs',
  //         filter: '' // Add your filters if needed (e.g., specific job IDs)
  //       }
  //     };
  //
  //     // Send the subscribe message to Supabase
  //     socket.send(JSON.stringify(subscribeMessage));
  //   };
  //
  //   // Step 4: Handle incoming messages
  //   socket.onmessage = (event) => {
  //     const data = JSON.parse(event.data);
  //     console.log('Message received from Supabase:', data);
  //
  //     if (data.type === 'postgres_changes' && data.payload.event === 'UPDATE') {
  //       console.log(
  //         'Received an update for post_found_item_jobs:',
  //         data.payload.new
  //       );
  //     }
  //   };
  //
  //   // Step 5: Handle WebSocket errors
  //   socket.onerror = (error) => {
  //     console.error('WebSocket error:', error);
  //   };
  //
  //   // Step 6: Handle WebSocket close
  //   socket.onclose = () => {
  //     console.log('WebSocket connection closed');
  //   };
  //
  //   // Cleanup on component unmount
  //   return () => {
  //     if (socket.readyState === 1) {
  //       // <-- This is important
  //       socket.close();
  //       console.log('WebSocket  socket.close');
  //     }
  //   };
  // }, []);

  onPreviewItemPosted((newFoundItemsIds) => {
    Api.get
      .foundItems({
        location_id: location!.id,
        archived: false,
        customQuery: (q) => q.in('id', newFoundItemsIds)
      })
      .then((newFoundItems) => {
        if (!newFoundItems || isArchivedItems) return;
        setFoundItems((prevItems) => [...newFoundItems, ...prevItems]);
      });
  });

  onImageDetectionUpdateItems((newFoundItemsIds) => {
    Api.get
      .foundItems({
        location_id: location!.id,
        archived: false,
        customQuery: (q) => q.in('id', newFoundItemsIds)
      })
      .then((newFoundItems) => {
        if (!newFoundItems || isArchivedItems) return;

        setFoundItems((prevItems) => {
          const filteredItems = prevItems.filter(
            (item) => !newFoundItemsIds.includes(item.id)
          );

          return [...newFoundItems, ...filteredItems];
        });
      });
  });

  // onItemPosted((foundItemsIds) => {
  //   if (isArchivedItems || !location) return;
  //
  //   Api.get
  //     .foundItems({
  //       location_id: location.id,
  //       archived: false,
  //       customQuery: (q) => q.in('id', foundItemsIds)
  //     })
  //     .then((newFoundItems) => {
  //       console.log('RAN newFoundItems', newFoundItems);
  //       if (!newFoundItems) return;
  //       setFoundItems((prevItems) => [...newFoundItems, ...prevItems]);
  //       showSnackbar(
  //         newFoundItems.length === 1 ? (
  //           <>
  //             Genstand tilføjet.{' '}
  //             <a
  //               style={{ textDecoration: 'underline' }}
  //               href={Page.l.viewFoundItem(location.id, newFoundItems[0].id)}
  //             >
  //               Se genstand.
  //             </a>
  //           </>
  //         ) : (
  //           `${newFoundItems.length} genstande tilføjet.`
  //         )
  //       );
  //       newFoundItems.map((item) => {
  //         if (item.used_image_detection) {
  //           // if using image detection with a payment card or id
  //           if (
  //             item.category.id === 200 ||
  //             item.category.id === 201 ||
  //             item.category.id === 202 ||
  //             item.category.id === 203
  //           ) {
  //             showSnackbar(
  //               'Vi anbefaler at du bruger "Nyt kort", ved oprettelse af kort, for ikke at uploade følsomme data',
  //               'warning'
  //             );
  //           }
  //         }
  //       });
  //       Api.get
  //         .matches({
  //           customQuery: (query) =>
  //             query
  //               .in(
  //                 'found_item_id',
  //                 newFoundItems.map((item) => item.id)
  //               )
  //               .eq('match_rejected', false)
  //               .eq('match_confirmed', false)
  //         })
  //         .then((matchesDataResponse) =>
  //           setMatchesData((prev) => [...prev, ...matchesDataResponse])
  //         );
  //     });
  // });

  const updateFoundItem = (
    id: number,
    newValue: Partial<FoundItem | ArchivedFoundItem>
  ) => {
    console.log('RAN updateFoundItem', id, newValue);
    setFoundItems((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, ...newValue } : item
      )
    );
  };

  const categoryFilter = (item: FoundItem) =>
    filters.category
      ? item.category.parent_category_id === filters.category
      : true;

  const queryFilter = (item: FoundItem) =>
    filters.query
      ? item.category.name_danish
          .toLowerCase()
          .includes(filters.query.toLowerCase()) ||
        item.description.toLowerCase().includes(filters.query.toLowerCase())
      : true;

  const createdAtFilter = (item: FoundItem) => {
    if (!filters.createdAtFrom) return true;
    const itemDate = new Date(item.created_at); // Parse the item's createdAt date
    const fromDate = filters.createdAtFrom
      ? new Date(filters.createdAtFrom)
      : null;
    const toDate = filters.createdAtTo ? new Date(filters.createdAtTo) : null;

    return (
      (!fromDate || itemDate >= fromDate) && // Check if item is after or on the start date
      (!toDate || itemDate <= toDate) // Check if item is before or on the end date
    );
  };

  if (!location) return null;

  const handleTabClick = (index: number) => {
    setSelectedTabIndex(index);
    updateFilter({
      filterItems: (getTabs(0).at(index) as any).searchParamValue
    });
  };

  const callbacks = {
    onUpdateItem: updateFoundItem,
    onArchive: removeFoundItemLocally,
    onUnArchive: removeFoundItemLocally
  };

  const filteredItemsBasedOnTab: FoundItem[] = getTabs(0)[selectedTabIndex]
    .filter
    ? (getTabs(0)[selectedTabIndex].filter as any)(foundItems, matchesData)
    : foundItems;

  const fullyFilteredItems = filteredItemsBasedOnTab.filter(
    (item) => queryFilter(item) && categoryFilter(item) && createdAtFilter(item)
  );

  const uniqueQueryFilterOptions = Array.from(
    new Set([
      ...fullyFilteredItems.map((item) => item.description),
      ...fullyFilteredItems.map((item) => item.category.name_danish)
    ])
  );

  return (
    <div>
      <Tabs
        tabs={getTabs(
          filterItemsWithPossibleMatches(foundItems, matchesData).length
        )}
        onTabClick={handleTabClick}
        selectedTabIndex={selectedTabIndex}
      />
      <div className={styles.tabDescription__container}>
        <Text type="caption">{getTabs(0)[selectedTabIndex].description}</Text>
      </div>

      <ItemFilters
        uniqueQueryFilterOptionsForSarch={uniqueQueryFilterOptions}
      />

      {isLoading ? (
        <LoadingSpinner centered />
      ) : (
        <>
          {!filters.filterItems && (
            <AllFoundItemsTable
              foundItems={fullyFilteredItems}
              callbacks={callbacks}
            />
          )}
          {filters.filterItems === 'matches' && (
            <PossibleMatchesTable
              itemsWithPossibleMatch={fullyFilteredItems}
              matchesData={matchesData}
              callbacks={callbacks}
            />
          )}
          {filters.filterItems === 'pickup' && (
            <AwaitingPickupTable
              itemsAwaitingPickup={fullyFilteredItems}
              callbacks={callbacks}
            />
          )}
          {filters.filterItems === 'expired' && (
            <ExpiredOrLeftBehindTable
              expiredOrLeftBehindItems={fullyFilteredItems}
              callbacks={callbacks}
            />
          )}
          {filters.filterItems === 'archived' && (
            <ArchivedFoundItemsTable
              foundItems={fullyFilteredItems}
              callbacks={callbacks}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FoundItemsTab;
