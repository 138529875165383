import { supabase } from '../../../supabase/supabaseClient';
import { PostFoundItemJobsRecord } from '../../../../types/supabase/collections';

export function subscribeToFoundItemJobs(
  jobIds: number[],
  callback: (job: PostFoundItemJobsRecord) => void
) {
  const channel = supabase
    .channel(`post_found_item_jobs_updates:${jobIds.join(',')}`)
    .on(
      'postgres_changes',
      {
        event: 'UPDATE', // Listen to UPDATE events
        schema: 'public',
        table: 'post_found_item_jobs',
        filter: `id=in.(${jobIds.join(',')})` // Filter by job IDs
      },
      (payload) => {
        console.log('subscribeToFoundItemJobs ran', payload);
        const job = payload.new as PostFoundItemJobsRecord;
        callback(job);
      }
    )
    .subscribe();

  return () => {
    supabase.removeChannel(channel);
  };
}
