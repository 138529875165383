import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FoundItem,
  Inquiry
} from '../../../../../../types/supabase/collections';
import { useModalNavigation } from '../../../../../../sharedPacakge/components/Modal/Modal';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import JoinLeftOutlinedIcon from '@mui/icons-material/JoinLeftOutlined';
import { TextField } from '../../../../../../components/TextField/TextField';
import { Text } from '../../../../../../components/Text/Text';
import { getIsFoundItem } from '../../../../../../servieces/Api/methods/put/archiveItems';
import { useSnackbar } from '../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import Api from '../../../../../../servieces/Api/Api';
import {
  getRecords,
  getRecordsCustomQuery,
  updateRecord
} from '../../../../../../servieces/supabase/getRecord';
import { ItemStatus } from '../../../../../../sharedPacakge/types/ItemStatus';
import { handleAccpetedMatchEmails } from '../../../../../../servieces/matchmaking/approveMatch';

interface Props<T> {
  item: T;
  matchingItem: Inquiry | FoundItem | null;
}

function EditContent<T extends FoundItem | Inquiry>({ item }: Props<T>) {
  const { navigation, setNavigation } = useModalNavigation();
  const { showSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<number | undefined>(undefined);
  const isFoundItem = getIsFoundItem(item);

  const handleSubmit = useCallback(async () => {
    // Checking if user has access to the item & its in the same location
    if (!value) {
      showSnackbar('ID mangler', 'warning');
      return;
    }
    const lookupMatches = await getRecords(
      isFoundItem ? 'inquiries' : 'found_items',
      [value]
    );
    if (
      !lookupMatches ||
      lookupMatches.length !== 1 ||
      lookupMatches[0].location_id !== item.location_id
    ) {
      showSnackbar(
        `Dette ID matcher ikke en valid ${isFoundItem ? 'genstand' : 'efterlysning'} i denne lokation og organisation`,
        'warning'
      );
      return;
    }
    const match = lookupMatches[0];

    // Ensuring that the lookup match dont already have a confirmed match
    const lookupMatchMatchesQuery = await getRecordsCustomQuery(
      'matches',
      (q) =>
        q
          .eq(isFoundItem ? 'inquiry_id' : 'found_item_id', match.id)
          .eq('match_confirmed', true)
    );
    if (lookupMatchMatchesQuery && lookupMatchMatchesQuery.length > 0) {
      showSnackbar(
        `Den ${isFoundItem ? 'genstand' : 'efterlysning'} har allerede et match`,
        'warning'
      );
      return;
    }

    // delete matches & set match
    await Api.delete.matches((q) =>
      q.eq(isFoundItem ? 'found_item_id' : 'inquiry_id', item.id)
    );
    await Api.delete.matches((q) =>
      q.eq(isFoundItem ? 'inquiry_id' : 'found_item_id', match.id)
    );
    await Api.post.match({
      inquiry_id: isFoundItem ? value : item.id,
      found_item_id: isFoundItem ? item.id : value,
      confirmed_or_rejected_at: new Date().toISOString(),
      match_confirmed: true,
      location_id: item.location_id,
      organisation_id: item.organisation_id
    });

    // ændre begge genstandes status
    await updateRecord('found_items', isFoundItem ? item.id : match.id, {
      status: ItemStatus.ReadyForPickup
    });
    await updateRecord('inquiries', isFoundItem ? match.id : item.id, {
      status: ItemStatus.ReadyForPickup
    });

    await handleAccpetedMatchEmails({
      inquiryId: isFoundItem ? value : item.id,
      organisationId: item.organisation_id
    });
    // reload instead of setting navitagion to null, because i am too lazy to make the states to handle displaying manual matching. So we relead to refetch
    window.location.reload();
  }, [value, setNavigation]);

  useEffect(() => {
    if (navigation) {
      // Only update navigation if needed
      setNavigation((prev) => {
        if (prev && prev.onSubmit !== handleSubmit) {
          return {
            ...prev,
            onSubmit: handleSubmit
          };
        }
        return prev; // Ensure we return prev in both branches
      });
    }

    // Focus the input when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
      const length = inputRef.current.value.length; // Get the current text length
    }
  }, [navigation, handleSubmit]); // handleSubmit is still a dependency, but we mitigate the issue

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <TextField
      type="number"
      value={value}
      onChange={(e) =>
        setValue(e.target.value ? Number(e.target.value) : undefined)
      }
      placeholder={isFoundItem ? 'ID på efterlysning' : 'ID på fundet genstand'}
      onKeyDown={handleKeyDown}
      inputRef={inputRef}
    />
  );
}

export function ManuelMatchButton<T extends FoundItem | Inquiry>(
  props: Props<T>
) {
  const { setNavigation } = useModalNavigation();

  const onClick = (e: any) => {
    e.preventDefault();
    setNavigation({
      title: 'Manuel match',
      // this has to be another component, in order for it to keep its state once it slides out.
      content: <EditContent {...props} />,
      onBack: () => setNavigation(null),
      onSubmit: () => {}
    });
  };

  return props.matchingItem ? null : (
    <Button onClick={onClick} buttonType="white">
      <Text type="body-strong">Match manuelt</Text>
      <JoinLeftOutlinedIcon />
    </Button>
  );
}
