import { encodeIntId } from '../sharedPacakge/utils/IntIdEncryption';

class Page {
  static createOrganisation = '/create-organisation';
  static login = '/login';
  static signup = '/signup';
  static resetPassword = '/reset-password';
  static confirmResetPassword = '/confirm-reset-password';

  static l = class {
    // static actions(locationId: number) {
    //   return `/l/${encodeIntId(locationId)}/actions`;
    // }

    static matchmaking(locationId: number, foundItemId: number) {
      return `/l/${encodeIntId(locationId)}/found-items/matchmaking/${foundItemId}`;
    }

    static foundItems(locationId: number) {
      return `/l/${encodeIntId(locationId)}/found-items`;
    }
    static foundItemsPossibleMatches(locationId: number) {
      return `/l/${encodeIntId(locationId)}/found-items?filter-items=matches`;
    }
    static viewFoundItem(locationId: number, foundItemId: number) {
      return `/l/${encodeIntId(locationId)}/found-items?view-item=${foundItemId}`;
    }
    static viewArchivedFoundItem(locationId: number, foundItemId: number) {
      return `/l/${encodeIntId(locationId)}/found-items?filter-items=archived&view-item=${foundItemId}`;
    }
    static viewInquiry(locationId: number, inquiry: number) {
      return `/l/${encodeIntId(locationId)}/inquiries?view-item=${inquiry}`;
    }
    static viewArchivedInquiry(locationId: number, inquiry: number) {
      return `/l/${encodeIntId(locationId)}/inquiries?filter-items=archived&view-item=${inquiry}`;
    }

    static matchedFoundI(locationId: number) {
      return `/l/${encodeIntId(locationId)}/found-items`;
    }
    static inquiries(locationId: number) {
      return `/l/${encodeIntId(locationId)}/inquiries`;
    }
    static customization = class {
      static general(locationId: number) {
        return `/l/${encodeIntId(locationId)}/customization/general`;
      }
      static editInquiryForm(locationId: number) {
        return `/l/${encodeIntId(locationId)}/customization/edit-inquiry-form`;
      }
      static emailTemplates(locationId: number) {
        return `/l/${encodeIntId(locationId)}/customization/email-templates`;
      }
      static users(locationId: number) {
        return `/l/${encodeIntId(locationId)}/customization/users`;
      }
    };

    static feedback(locationId: number) {
      return `/l/${encodeIntId(locationId)}/feedback`;
    }
  };

  static external = class {
    static lostSomething(organisationId: number, organisationName: string) {
      return `https://lost.findy.dk/form/da/${encodeIntId(organisationId)}/${encodeURIComponent(organisationName)}`;
    }
    static lostSomethingIframe(
      organisationId: number,
      organisationName: string
    ) {
      return `https://lost.findy.dk/form/da/${encodeIntId(organisationId)}/${encodeURIComponent(organisationName)}?iframe=true`;
    }
  };
}

export default Page;

// Utility type to extract return types from functions
export type ExtractRouteStrings<T> = T extends (...args: any[]) => infer R
  ? R
  : never;

// Recursively extract return types from an object type
export type ExtractRoutes<T> = T extends { [key: string]: any }
  ? {
      [K in keyof T]: T[K] extends (...args: any[]) => any
        ? ExtractRouteStrings<T[K]>
        : ExtractRoutes<T[K]>;
    }[keyof T]
  : never;

export type PageType = ExtractRoutes<typeof Page>;
