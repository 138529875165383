import React, { useEffect, useState } from 'react';
import styles from './viewInquiryModal.module.css';
import {
  ArchivedFoundItem,
  ArchivedInquiry,
  FoundItem,
  Inquiry
} from '../../../../../types/supabase/collections';
import { useLocationData } from '../../../../../contexts/LocationDataContext/LocationDataContext';
import { useSnackbar } from '../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { InquiryArchiveReason } from '../../../../../sharedPacakge/types';
import { ItemStatus } from '../../../../../sharedPacakge/types/ItemStatus';
import Api from '../../../../../servieces/Api/Api';
import logger from '../../../../../sharedPacakge/logger';
import { getDateDaysFromNow } from '../../../../../utils/dateInXDays';
import { Modal } from '../../../../../sharedPacakge/components/Modal/Modal';
import { LoadingSpinner } from '../../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import { TranslateViewItemModalStatus } from '../../../../../utils/translate/translateItemStatus';
import { formatDate2 } from '../../../../../utils/formatting/formatDate';
import { daysUntilDateText } from '../../../../../utils/daysUntilDate';
import { ViewItemModalCallbacks } from '../ViewItemModalCallbacks';
import useFilters from '../../../hooks/useFilters';
import { Text } from '../../../../../components/Text/Text';
import { ResponsiveGridContainer } from '../../../../../components/ResponsiveGridContainer/ResponsiveGridContainer';
import { TextField } from '../../../../../components/TextField/TextField';
import { ArchiveItemButton } from '../Fields/ArchiveItemButton/ArchiveItemButton';
import { UnArchiveItemButton } from '../Fields/UnArchiveItemButton/UnArchiveItemButton';
import { MatchSection } from '../Sections/MatchSection/MatchSection';
import { StatusCircle } from '../../StatusCircle/StatusCircle';
import { TextArea } from '../../../../../components/TextArea/TextArea';
import GalleryImage from '../GalleryImage/GalleryImage';
import { ManuelMatchButton } from '../Fields/ManuelMatchButton/ManuelMatchButton';

interface Props<T> {
  inquiry: T;
  callbacks: ViewItemModalCallbacks<T>;
}
function ViewInquiryModal<T extends Inquiry | ArchivedInquiry>({
  inquiry,
  callbacks
}: Props<T>) {
  const isArchivedItem = 'archive_reason' in inquiry;
  const { location, itemCategories } = useLocationData();
  const { showSnackbar } = useSnackbar();
  const [resolveReason, setResolveReason] = useState<InquiryArchiveReason | ''>(
    ''
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [match, setMatch] = useState<FoundItem | ArchivedFoundItem | null>(
    null
  );
  const [_, udpateFilter] = useFilters();
  const closeModal = () => udpateFilter({ viewItem: null });

  useEffect(() => {
    if (
      inquiry.status === ItemStatus.ReadyForPickup ||
      inquiry.status === ItemStatus.LeftBehind
    ) {
      setIsLoading(true);

      Api.get
        .matches(
          {
            customQuery: (query) =>
              query.eq('inquiry_id', inquiry.id).eq('match_confirmed', true)
          },
          isArchivedItem
        )
        .then((matches) => {
          if (matches.length !== 1) {
            // inquiry will canno tbe manually sat to pickup, therefor we expect a match when the status is one of the above.
            logger.error(
              `ViewInquiryModal !matches || matches.length !== 1 -> ${JSON.stringify(matches)}`
            );
            return;
          }
          Api.get
            .foundItem(matches[0].found_item_id, isArchivedItem)
            .then((foundItem) => {
              if (!foundItem) return;
              setMatch(foundItem);
              setIsLoading(false);
            });
        });
    }
  }, []);

  const onResolveClick = (e: any) => {
    e.preventDefault();
    if (!resolveReason) {
      showSnackbar('Mangler årsag', 'error');
      return;
    }

    setIsLoading(true);
    Api.put
      .archiveItems({
        ids: [inquiry.id],
        type: 'inquiries',
        archiveReason: resolveReason
      })
      .then(() => {
        callbacks?.onArchive && callbacks.onArchive(inquiry.id);
        setIsLoading(false);
        showSnackbar('Afviklet');
        closeModal();
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'));
  };

  const onReopenClick = (e: any) => {
    e.preventDefault();
    if (!isArchivedItem) return;

    setIsLoading(true);
    Api.put
      .unArchiveItems({
        ids: [inquiry.id],
        type: 'inquiries',
        newExpiryDate: getDateDaysFromNow(10)
      })
      .then(() => {
        callbacks?.onUnArchive && callbacks.onUnArchive(inquiry.id);
        setIsLoading(false);
        showSnackbar('genåbnet');
        closeModal();
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'));
  };

  if (!location) return null;

  return (
    <Modal
      className={styles.modal}
      title={isArchivedItem ? 'Arkiveret efterlysning' : 'Efterlysning'}
      onClose={closeModal}
    >
      <div className={styles.container}>
        <div className={styles.sectionContainer}>
          <GalleryImage image={inquiry?.image} />
        </div>

        <TextField
          label="Status"
          value={TranslateViewItemModalStatus(inquiry)}
          adornment={{
            beforeValue: <StatusCircle item={inquiry} />
          }}
          disabled
        />

        <div className={styles.sectionContainer}>
          <Text type="body-strong">Genstand</Text>
          <ResponsiveGridContainer>
            <TextField
              label="Kategori"
              value={inquiry.category.name_danish}
              disabled
            />
            <TextArea
              label="Beskrivelse"
              value={inquiry.description}
              disabled
            />
          </ResponsiveGridContainer>
        </div>

        <div className={styles.sectionContainer}>
          <Text type="body-strong">Håndtering</Text>
          <ResponsiveGridContainer>
            <TextField
              label="mistet ved"
              value={
                inquiry.lost_at_location === ''
                  ? 'ikke specificeret'
                  : inquiry.lost_at_location
              }
              disabled
            />
            <TextField
              label="Mistet Omkring"
              value={formatDate2(inquiry.lost_at_date)}
              disabled
            />
            {!isArchivedItem && (
              <TextField
                label="Udløber om"
                value={daysUntilDateText(inquiry.expiry_date, 'long')}
                disabled
              />
            )}
          </ResponsiveGridContainer>
        </div>

        <div className={styles.sectionContainer}>
          <Text type="body-strong">Efterlyst af</Text>
          <ResponsiveGridContainer>
            <TextField label="navn" value={inquiry.reporter_name} disabled />
            <TextField label="email" value={inquiry.reporter_email} disabled />
            <TextField
              label="telefon"
              value={inquiry.reporter_phone}
              disabled
            />
          </ResponsiveGridContainer>
        </div>

        <MatchSection
          item={inquiry}
          matchingItem={match}
          callbacks={callbacks}
          closeModal={closeModal}
          isArchivedItem={isArchivedItem}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />

        <div className={styles.sectionContainer}>
          <Text type="body-strong">Manuel Håndtering</Text>
          <ResponsiveGridContainer>
            <TextField label="ID" value={inquiry.id} disabled />
            {!isArchivedItem && (
              <ArchiveItemButton
                item={inquiry}
                callbacks={callbacks}
                onClose={closeModal}
              />
            )}
            {isArchivedItem && (
              <UnArchiveItemButton
                item={inquiry}
                callbacks={callbacks as ViewItemModalCallbacks<ArchivedInquiry>}
                onClose={closeModal}
              />
            )}
            <ManuelMatchButton item={inquiry} matchingItem={match} />
          </ResponsiveGridContainer>
        </div>

        {isLoading && <LoadingSpinner />}
      </div>
    </Modal>
  );
}

export default ViewInquiryModal;
