import React from 'react';
import styles from './generalCustomizationTab.module.css';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import { LocationsOverview } from './components/LocationsOverview/LocationsOverview';
import { ExpiryDateOptions } from './components/ExpiryDateOptions/ExpiryDateOptions';
import { StorageAndFoundLocationOptions } from './components/StorageAndFoundLocationOptions/StorageAndFoundLocationOptions';
export const GeneralCustomizationTab = () => {
  const { organisation, location } = useLocationData();

  return (
    <div className={styles.pageContainer}>
      <div className={styles.container}>
        <h3 className={styles.locations__title}>
          Lokationer i {organisation?.name}
        </h3>
        <LocationsOverview />
      </div>

      <div className={styles.container}>
        <h3 className={styles.locations__title}>
          Udløbs indstillinger for {location?.name}
        </h3>
        <ExpiryDateOptions />
      </div>

      <div className={styles.container}>
        <h3 className={styles.locations__title}>
          Konfigurer felter for "opret ny genstand" hos {location?.name}
        </h3>
        <StorageAndFoundLocationOptions />
      </div>
    </div>
  );
};
