export function formatDate(isoDate: string): string {
  const date = new Date(isoDate);

  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    day: 'numeric',
    month: 'short'
  };

  // Create the formatter
  const formatter = new Intl.DateTimeFormat('da-DK', options);

  // Get the parts of the formatted date
  const parts = formatter.formatToParts(date);

  // Extract the parts
  const hour = parts.find((part) => part.type === 'hour')?.value;
  const minute = parts.find((part) => part.type === 'minute')?.value;
  const day = parts.find((part) => part.type === 'day')?.value;
  const month = parts.find((part) => part.type === 'month')?.value;

  // Construct the formatted string
  return `kl. ${hour}:${minute} - ${day} ${month}`;
}

export function formatDateOnly(isoDate: string): string {
  const date = new Date(isoDate);

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long'
  };

  // Create the formatter
  const formatter = new Intl.DateTimeFormat('da-DK', options);

  // Get the parts of the formatted date
  const parts = formatter.formatToParts(date);

  // Extract the parts
  const day = parts.find((part) => part.type === 'day')?.value;
  const month = parts.find((part) => part.type === 'month')?.value;

  // Construct the formatted string
  return `${day} ${month}`;
}

export function formatDate2(isoDate: string): string {
  const date = new Date(isoDate);

  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: 'long'
  };

  // Create the formatter
  const formatter = new Intl.DateTimeFormat('da-DK', options);

  // Get the parts of the formatted date
  const parts = formatter.formatToParts(date);

  // Extract the parts
  const hour = parts.find((part) => part.type === 'hour')?.value;
  const minute = parts.find((part) => part.type === 'minute')?.value;
  const day = parts.find((part) => part.type === 'day')?.value;
  const month = parts.find((part) => part.type === 'month')?.value;

  // Construct the formatted string
  return `${day} ${month} kl. ${hour}:${minute}`;
}
