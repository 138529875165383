import styles from './usersTab.module.css';
import React, { FC, useEffect, useState } from 'react';
import { Role } from '../../../../types/supabase/collections';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import { useAuth } from '../../../../contexts/AuthContext/AuthContext';
import Api from '../../../../servieces/Api/Api';
import GenericTable, {
  Column
} from '../../../../components/GenericTable/GenericTable';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import { EllipsisMenu } from '../../../../components/EllipsisMenu/EllipsisMenu';
import { objectsAreEqual } from '../../../../utils/arraysAreEqual';
import { useSnackbar } from '../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { Button } from '../../../../sharedPacakge/components/Button/Button';
import { daysUntilDate } from '../../../../utils/daysUntilDate';
import {
  UserOrInvite,
  UserTabUser
} from '../../../../servieces/Api/methods/get/getUsersAndInvites';
import CircleImage from '../../components/OrganisationLogo/OrganisationLogo';
import { SearchBar } from '../../../../components/SearchBar/SearchBar';
import useFilters from '../../hooks/useFilters';
import AddIcon from '@mui/icons-material/Add';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { EditUserModal } from './components/EditUserModal/EditUserModal';
import { translateRole } from '../../../../utils/translate/translateRole';
import { AddUserModal } from './components/AddUserModal/AddUserModal';
import { ResponsiveGridContainer } from '../../../../components/ResponsiveGridContainer/ResponsiveGridContainer';
import { daysBetweenDates } from '../../../../utils/daysBetweenDays';

export const UsersTab: FC = () => {
  const { user } = useAuth();
  const [filters] = useFilters();
  const { location, roleAccess, organisation } = useLocationData();
  const { showSnackbar } = useSnackbar();
  const [usersAndInvites, setUsersAndInvites] = useState<UserOrInvite[]>([]);
  const [addUserModalIsOpen, setAddUserModalIsOpen] = useState<boolean>(false);
  const [locations, setLocations] = useState<{ id: number; name: string }[]>(
    []
  );
  const [editUserWithId, setEditUserWithId] = useState<string | null>(null);
  const [locationsUserHasAccessTo, setLocationsUserHasAccessTo] = useState<
    { id: number; name: string }[]
  >([]);

  const fetchAdminList = () => {
    if (!organisation || !location || !user) return;

    Api.get
      .allLocationsInOrganisation(organisation.id)
      .then((locationsResponse) => {
        setLocations(locationsResponse);
        Api.get
          .usersAndInvites(
            organisation.id,
            locationsResponse.map((loc) => loc.id)
          )
          .then((response) => {
            // put own user first
            const ownUser = response.find((u) => u.id === user.id);

            if (ownUser) {
              const remainingUsers = response.filter((u) => u.id !== user.id);
              const sortedResponse = [ownUser, ...remainingUsers];
              setUsersAndInvites(sortedResponse);
            } else {
              // for super user we wont have a own user returned
              setUsersAndInvites(response);
            }
          })
          .catch(() => showSnackbar('Noget git galt', 'error'));
      })
      .catch(() => showSnackbar('Noget git galt', 'error'));

    Api.get
      .allLocationsWhereUserIsAdminWithingOrganisation({
        userId: user.id,
        organisationId: organisation.id
      })
      .then((response) => {
        setLocationsUserHasAccessTo(response);
      });
  };

  useEffect(() => {
    fetchAdminList();
  }, [user, organisation, location]);

  if (!organisation || !user) return null;

  const onDeleteClick = async (adminOrInviteToDelete: UserOrInvite) => {
    try {
      if (adminOrInviteToDelete.isUserInvite) {
        await Api.delete.userInvite(adminOrInviteToDelete.id);
      } else {
        await Api.delete.userRolesInOrg(
          adminOrInviteToDelete.id,
          organisation.id
        );
      }

      showSnackbar(`Bruger blev fjernet`);
      setUsersAndInvites((prevState) =>
        prevState.filter(
          (userOrInvite) =>
            !objectsAreEqual(adminOrInviteToDelete, userOrInvite)
        )
      );
    } catch (error) {
      showSnackbar('Der skete en fejl');
    }
  };

  const tableColoumns: Column<UserOrInvite>[] = [
    {
      id: 'image',
      label: '',
      render: ({ fullName }) => <CircleImage altText={fullName} />,
      renderMobile: true
    },
    {
      id: 'fullName',
      label: 'Navn',
      render: ({ fullName }) => fullName,
      renderMobile: true
    },
    {
      id: 'email',
      label: 'Email',
      render: ({ email }) => email,
      renderMobile: true
    },
    {
      id: 'role',
      label: 'Administrator af',
      render: ({ role }) => translateRole(role),
      renderMobile: true
    },
    {
      id: 'access',
      label: 'Adgang',
      render: ({ role, locationIds }) => {
        if (role === Role.OrganisationAdmin) return 'Alle';
        if (locationIds.length === 1)
          return locations.find((l) => l.id === locationIds[0])!.name;
        return `${locationIds.length} lokationer`;
      },
      renderMobile: true
    },
    {
      id: 'login_status',
      label: 'Login status',
      render: (row) => {
        if (row.isUserInvite) {
          const days = daysUntilDate(row.expiryDate);
          return `Inviteret (${
            days === 0 ? 'udløber idag' : `${days} dage tilbage`
          })`;
        } else {
          const days = daysBetweenDates(new Date(row.lastActiveAt), new Date());

          if (days === 0) return 'idag';
          if (days < 30) return `${days} dage siden`;
          return (
            <div style={{ color: 'var(--color-negative)' }}>
              {days} dage siden
            </div>
          );
        }
      }
    }
  ];

  const queryFilter = (user: UserOrInvite) =>
    filters.query
      ? user.fullName.toLowerCase().includes(filters.query.toLowerCase()) ||
        user.email.toLowerCase().includes(filters.query.toLowerCase())
      : true;

  const filteredUsers = filters.query
    ? usersAndInvites.filter((user) => queryFilter(user))
    : usersAndInvites;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.searchBar__container}>
          <SearchBar
            placeholder="Navn eller email"
            options={filteredUsers.map((user) => user.email)}
          />
        </div>
        {roleAccess?.manageUsers?.length !== 0 && (
          <Button
            className={styles.addUser__button}
            buttonType="white"
            onClick={(e) => {
              e.preventDefault();
              setAddUserModalIsOpen(true);
            }}
          >
            Tilføj bruger
            <AddIcon />
          </Button>
        )}
      </div>
      <GenericTable<UserOrInvite>
        columns={tableColoumns}
        data={filteredUsers}
        rowKey="id"
        actionsColumn={(row) => {
          if (
            roleAccess?.manageUsers?.length !== 0 &&
            roleAccess.manageUsers?.includes(row.role)
          ) {
            const isSelf = row.id === user.id;

            let options = [];
            if (!row.isUserInvite && !isSelf)
              options.push({
                label: 'Rediger',
                icon: <ModeEditOutlineOutlinedIcon />,
                onClick: () => setEditUserWithId(row.id)
              });

            let canRemoveUser = true;
            if (
              row.locationIds.filter(
                (locationId) =>
                  !locationsUserHasAccessTo.some(({ id }) => id === locationId)
              ).length > 0
            )
              canRemoveUser = false;

            if (canRemoveUser)
              options.push({
                label: isSelf ? 'Forlad' : 'Fjern',
                icon: <PersonRemoveRoundedIcon />,
                color: 'negative' as 'negative',
                onClick: () => onDeleteClick(row)
              });

            return <EllipsisMenu options={options} />;
          }
          return null;
        }}
      />
      {editUserWithId && (
        <EditUserModal
          user={
            usersAndInvites.find((u) => u.id === editUserWithId) as UserTabUser
          }
          locationsUserHasAccessTo={locationsUserHasAccessTo}
          allLocations={locations}
          onClose={() => setEditUserWithId(null)}
          setUsersAndInvites={setUsersAndInvites}
        />
      )}
      {addUserModalIsOpen && (
        <AddUserModal
          onClose={() => setAddUserModalIsOpen(false)}
          setUsersAndInvites={setUsersAndInvites}
          currentUsers={usersAndInvites}
        />
      )}
    </div>
  );
};
