import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import React from 'react';
import Page from '../Page';
import { encodeIntId } from '../../sharedPacakge/utils/IntIdEncryption';
import { Role } from '../../types/supabase/collections';

export const tabsConfiguration = (locationId: number) => [
  // {
  // name: 'Handlinger',
  // description: 'Tag handling ét sted',
  // icon: <PendingActionsIcon />,
  // route: Page.l.actions(locationId),
  // urlPattern: `/l/${encodeIntId(locationId)}/actions`,
  // subTabs: [
  //   {
  //     name: 'Matchmaking',
  //     description: 'Matchmaking',
  //     urlPattern: 'actions/matchmaking'
  //   }
  // ]
  // },
  {
    name: 'Fundet',
    description: 'Alle fundne genstande',
    icon: <CategoryOutlinedIcon />,
    route: Page.l.foundItems(locationId),
    urlPattern: `/l/${encodeIntId(locationId)}/found-items`
    // subTabs: [
    //   {
    //     name: 'Matchmaking',
    //     description: 'Matchmaking',
    //     urlPattern: 'found-items/matchmaking'
    //   }
    // ]
  },
  {
    name: 'Efterlyst',
    description: 'Alle efterlysninger',
    icon: <ThreePOutlinedIcon />,
    route: Page.l.inquiries(locationId),
    urlPattern: `/l/${encodeIntId(locationId)}/inquiries`,
    dividerAfter: true
  },
  {
    name: 'Tilpasning',
    description: 'Tilpasning',
    icon: <SettingsOutlinedIcon />,
    route: Page.l.customization.general(locationId),
    urlPattern: `/l/${encodeIntId(locationId)}/customization`
  },
  {
    name: 'Support',
    description: 'Feedback, forslag & fejl',
    icon: <QuestionAnswerOutlinedIcon />,
    route: Page.l.feedback(locationId),
    urlPattern: `/l/${encodeIntId(locationId)}/feedback`
  }
];
