import React, { useEffect, useState } from 'react';
import styles from './editInquiryForm.module.css';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import { PreviewInquiryForm } from './components/PreviewInquiryForm/PreviewInquiryForm';
import { InquiryFormRecord } from '../../../../types/supabase/collections';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import StayPrimaryPortraitIcon from '@mui/icons-material/StayPrimaryPortrait';
import { PxInputField } from './components/PxInputField/PxInputField';
import { FormControl, MenuItem, Select } from '@mui/material';
import Page from '../../../Page';
import Api from '../../../../servieces/Api/Api';
import { ColorInput } from '../../../../components/ColorPicker/ColorInput';
import { Button } from '../../../../sharedPacakge/components/Button/Button';
import { useSnackbar } from '../../../../sharedPacakge/components/Snackbar/SnackbarContext';

export type CustomInquiryFormStyling = Omit<
  InquiryFormRecord,
  'organisation_id' | 'updated_at' | 'created_at'
>;

const defaultStylingObjectValue: CustomInquiryFormStyling = {
  font_family: 'Nunito',
  background_color: '#ffffff',
  text_color: '#242424',
  input_border_color: '#ececec',
  input_background_color: '#f8f8f8',
  input_border_radius: '5px',
  button_background_color: '#2D7CFE',
  button_text_color: '#ffffff',
  button_border_radius: '5px',
  banner_url: 'none',
  logo_url: 'none'
};

const preInstalledFonts = ['Nunito'];
const webSafeFontOptions = [
  'Arial',
  'Verdana',
  'Helvetica',
  'Tahoma',
  'Trebuchet MS',
  'Times New Roman',
  'Georgia',
  'Courier New',
  'Brush Script MT',
  'Arial Black',
  'Comic Sans MS',
  'Lucida Console',
  'Lucida Sans Unicode'
];

const fontOptions = [...preInstalledFonts, ...webSafeFontOptions];

export const EditInquiryFormTab = () => {
  const { organisation, location } = useLocationData();
  const { showSnackbar } = useSnackbar();
  const [previewMode, setPreviewMode] = useState<'mobile' | 'desktop'>(
    'desktop'
  );
  const [stylingObject, setStylingObject] = useState<CustomInquiryFormStyling>(
    defaultStylingObjectValue
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!organisation) return;
    Api.get.inquiryForm(organisation.id).then((data) => {
      if (data) {
        const {
          organisation_id,
          updated_at,
          created_at,
          ...inquiryFormStylingData
        } = data;
        setStylingObject(inquiryFormStylingData);
      }
    });
  }, [organisation]);

  if (!organisation || !location) return null;

  const onMobileClick = (e: any) => {
    e.preventDefault();
    setPreviewMode('mobile');
  };
  const onDesktopClick = (e: any) => {
    e.preventDefault();
    setPreviewMode('desktop');
  };

  const onCopyClick = (e: any, type: 'link' | 'iframe') => {
    e.preventDefault();

    if (type === 'iframe') {
      const iframeString = `<iframe src="${Page.external.lostSomethingIframe(organisation.id, organisation.name)}" width="600" height="900" style="border:none;"></iframe>`;
      navigator.clipboard.writeText(iframeString);
    } else {
      const linkUrl = Page.external.lostSomething(
        organisation.id,
        organisation.name
      );
      navigator.clipboard.writeText(linkUrl);
    }

    showSnackbar(`${type} kopieret`);
  };

  const onSave = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    Api.upsert
      .upsertInquiryForm(organisation.id, stylingObject)
      .then(() => {
        setIsLoading(false);
        showSnackbar('Formularen er opdateret');
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'));
  };

  return (
    <div>
      <div className={styles.editForm__container}>
        <div className={styles.inputs__container}>
          <div className={styles.previewMode__container}>
            <span>Forhåndsvisning</span>
            <div
              className={styles.previewMode__button}
              style={previewMode === 'desktop' ? { background: '#75a9ff' } : {}}
              onClick={onDesktopClick}
            >
              <PersonalVideoIcon />
            </div>
            <div
              className={styles.previewMode__button}
              style={previewMode === 'mobile' ? { background: '#75a9ff' } : {}}
              onClick={onMobileClick}
            >
              <StayPrimaryPortraitIcon />
            </div>
          </div>

          <ColorInput
            label="Baggrundsfarve"
            value={stylingObject.background_color}
            onChange={(newValue) =>
              setStylingObject((prevState) => ({
                ...prevState,
                background_color: newValue
              }))
            }
            disabled={isLoading}
          />
          <ColorInput
            label="Tekstfarve"
            value={stylingObject.text_color}
            onChange={(newValue) =>
              setStylingObject((prevState) => ({
                ...prevState,
                text_color: newValue
              }))
            }
            disabled={isLoading}
          />
          <ColorInput
            label="Inputfelt: Baggrundsfarve"
            value={stylingObject.input_background_color}
            onChange={(newValue) =>
              setStylingObject((prevState) => ({
                ...prevState,
                input_background_color: newValue
              }))
            }
            disabled={isLoading}
          />
          <ColorInput
            label="Inputfelt: Kantfarve"
            value={stylingObject.input_border_color}
            onChange={(newValue) =>
              setStylingObject((prevState) => ({
                ...prevState,
                input_border_color: newValue
              }))
            }
            disabled={isLoading}
          />
          <ColorInput
            label="Knap: Baggrundsfarve"
            value={stylingObject.button_background_color}
            onChange={(newValue) =>
              setStylingObject((prevState) => ({
                ...prevState,
                button_background_color: newValue
              }))
            }
            disabled={isLoading}
          />
          <ColorInput
            label="Knap: Tekstfarve"
            value={stylingObject.button_text_color}
            onChange={(newValue) =>
              setStylingObject((prevState) => ({
                ...prevState,
                button_text_color: newValue
              }))
            }
            disabled={isLoading}
          />

          <PxInputField
            label="Inputfeltet: Hjørneradius"
            value={stylingObject.input_border_radius}
            onChange={(newValue) =>
              setStylingObject((prevState) => ({
                ...prevState,
                input_border_radius: newValue
              }))
            }
            disabled={isLoading}
          />
          <PxInputField
            label="Knap: Hjørneradius"
            value={stylingObject.button_border_radius}
            onChange={(newValue) =>
              setStylingObject((prevState) => ({
                ...prevState,
                button_border_radius: newValue
              }))
            }
            disabled={isLoading}
          />

          <FormControl fullWidth>
            <small>Skrifttype</small>
            <Select
              disabled={isLoading}
              value={stylingObject.font_family}
              onChange={(e: any) =>
                setStylingObject((prevState) => ({
                  ...prevState,
                  font_family: e.target.value
                }))
              }
              renderValue={(selected) => (
                <span style={{ fontFamily: selected }}>{selected}</span>
              )}
            >
              {fontOptions.map((font) => (
                <MenuItem key={font} value={font} style={{ fontFamily: font }}>
                  {font}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button className={styles.submit__button} onClick={onSave}>
            Gem ændringer
          </Button>
        </div>

        <div className={styles.preview__container}>
          <PreviewInquiryForm
            previewMode={previewMode}
            stylingObject={stylingObject}
          />
        </div>
      </div>
      <div className={styles.howToUseForm__container}>
        <h3 style={{ margin: 0 }}>Kom igang med at bruge din formular</h3>
        <small style={{ marginBottom: 10 }}>
          Link over til jeres formular på lost.findy eller indsæt en iframe på
          jeres egen hjemmeside
        </small>
        <div className={styles.howToUseForm__buttons}>
          <Button
            onClick={(e) => onCopyClick(e, 'link')}
            buttonType="secondary"
          >
            Kopier link
          </Button>
          <Button
            onClick={(e) => onCopyClick(e, 'iframe')}
            buttonType="secondary"
          >
            Kopier iframe
          </Button>
        </div>
      </div>
    </div>
  );
};
