import {
  ArchivedFoundItem,
  ArchivedInquiry,
  FoundItem,
  Inquiry
} from '../../../../types/supabase/collections';
import React, { FC } from 'react';
import { ItemStatus } from '../../../../sharedPacakge/types/ItemStatus';

export const StatusToColor = (
  item: FoundItem | ArchivedFoundItem | Inquiry | ArchivedInquiry
) => {
  let color;
  const isArchivedItem = 'archive_reason' in item;
  if (isArchivedItem) color = 'var(--color-gray-600)';
  else {
    switch (item.status) {
      case ItemStatus.AwaitingMatch:
        color = 'var(--color-primary)';
        break;
      case ItemStatus.ReadyForPickup:
        color = 'var(--color-positive)';
        break;
      case ItemStatus.LeftBehind:
        color = 'var(--color-warning)';
        break;
      case ItemStatus.Expired:
        color = 'var(--color-warning)';
        break;
    }
  }
  return color;
};

export const StatusCircle: FC<{
  item: FoundItem | ArchivedFoundItem | Inquiry | ArchivedInquiry;
  size?: 'small' | 'normal';
}> = ({ item, size = 'normal' }) => {
  const attributes =
    size === 'normal'
      ? {
          width: 18,
          height: 18
        }
      : {
          width: 14,
          height: 14
        };

  return (
    <svg {...attributes} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="10" fill={StatusToColor(item)} />
    </svg>
  );
};
