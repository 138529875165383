import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useDropzone } from 'react-dropzone';

interface BaseProps {
  resetStateOnUpload?: boolean;
  imageScaling?: {
    maxWidth: number;
    maxHeight: number;
  };
  renderText?: (images: string | string[]) => React.ReactNode;
  styles?: React.CSSProperties;
  className?: string;
}

type SingleUploadProps = {
  multiPost?: false;
  onFileUpload: (file: File) => void;
  previewImage?: File;
};

type MultiUploadProps = {
  multiPost: true;
  onFileUpload: (files: File[]) => void;
  previewImage?: File[];
};

type ImageUploaderProps<T extends boolean> = BaseProps &
  (T extends true ? MultiUploadProps : SingleUploadProps);

const ImageUploader = <T extends boolean>({
  multiPost,
  onFileUpload,
  previewImage,
  resetStateOnUpload = false,
  renderText,
  styles = {},
  className = ''
}: ImageUploaderProps<T>) => {
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  const previewImageToDataUrl = (files: File[]) => {
    const readers = files.map(
      (file) =>
        new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        })
    );

    Promise.all(readers).then(setImagePreviews).catch(console.error);
  };

  useEffect(() => {
    if (previewImage && !resetStateOnUpload) {
      const files = Array.isArray(previewImage) ? previewImage : [previewImage];
      previewImageToDataUrl(files);
    } else {
      setImagePreviews([]);
    }
  }, [previewImage, resetStateOnUpload]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (multiPost) {
        onFileUpload(acceptedFiles);
        previewImageToDataUrl(acceptedFiles);
      } else {
        const file = acceptedFiles[0];
        if (file) {
          onFileUpload(file);
          previewImageToDataUrl([file]);
        }
      }
    },
    [onFileUpload, multiPost]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png']
    },
    multiple: !!multiPost
  });

  return (
    <div
      {...getRootProps()}
      style={{ ...dropzoneStyles, ...styles }}
      className={className}
    >
      <input {...getInputProps()} />
      {renderText ? (
        renderText(imagePreviews)
      ) : imagePreviews.length > 0 ? (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            marginTop: '20px'
          }}
        >
          {imagePreviews.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Preview ${index + 1}`}
              style={{ maxWidth: '100%', maxHeight: '200px' }}
            />
          ))}
        </div>
      ) : (
        <p>Træk og slip en billedfil her, eller klik for at vælge et</p>
      )}
    </div>
  );
};

const dropzoneStyles: CSSProperties = {
  border: '2px dashed #ccc',
  borderRadius: '4px',
  textAlign: 'center',
  cursor: 'pointer'
};

export default ImageUploader;
