import styles from './itemFilter.module.css';
import { SearchBar } from '../../../../components/SearchBar/SearchBar';
import { Drawer } from '../../../../components/Drawer/Drawer';
import React, { FC, useState } from 'react';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import { Button } from '../../../../sharedPacakge/components/Button/Button';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { Text } from '../../../../components/Text/Text';
import { CategoryFilter } from './components/CategoryFilter/CategoryFilter';
import { CreatedAtFilter } from './components/CreatedAtFilter/CreatedAtFilter';
interface Props {
  uniqueQueryFilterOptionsForSarch: string[];
}
export const ItemFilters: FC<Props> = ({
  uniqueQueryFilterOptionsForSarch
}) => {
  const { itemCategories } = useLocationData();
  const [filterDrawerIsOpen, setFilterDrawerIsOpen] = useState<boolean>(false);

  const onMobileFilterButtonClick = (e: any) => {
    e.preventDefault();
    setFilterDrawerIsOpen(true);
  };

  return (
    <div className={styles.filters__container}>
      <div className={styles.searchBar__container}>
        <SearchBar
          placeholder="Kategori eller beskrivelse"
          options={uniqueQueryFilterOptionsForSarch}
        />
      </div>
      <div className={styles.otherFilters__container}>
        <Button
          buttonType="white"
          className={styles.mobileFilter__button}
          onClick={onMobileFilterButtonClick}
        >
          <TuneRoundedIcon />
        </Button>
        {filterDrawerIsOpen && (
          <Drawer setIsOpen={setFilterDrawerIsOpen} anchor="right">
            <div className={styles.drawer}>
              <Text type={'body-strong'}>Filtrer</Text>
              <CategoryFilter
                itemCategories={itemCategories
                  .filter((c) => !c.parent_category_id)
                  .sort((a, b) =>
                    a.name_danish.localeCompare(b.name_danish, undefined, {
                      sensitivity: 'base'
                    })
                  )}
              />
              <CreatedAtFilter />
            </div>
          </Drawer>
        )}
      </div>
    </div>
  );
};
