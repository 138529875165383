import React, { ReactNode } from 'react';
import styles from './tabs.module.css';

interface Tab<T> {
  icon?: ReactNode;
  label: T;
  activeUnderlineColor?: string;
  suffix?: ReactNode;
}

interface TabComponentProps<T extends string> {
  tabs: Tab<T>[];
  selectedTabIndex: number; // Should match the type of label
  onTabClick: (index: number) => void;
}

const Tabs = <T extends string>({
  tabs,
  selectedTabIndex,
  onTabClick
}: TabComponentProps<T>) => {
  return (
    <div className={styles.tabContainer}>
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`${styles.tab} ${index === selectedTabIndex ? styles.selected : ''}`}
          onClick={(e: any) => {
            e.preventDefault();
            onTabClick(index);
          }}
        >
          <div className={styles.content}>
            {tab?.icon}
            {tab.label}
            {tab.suffix && <span className={styles.suffix}>{tab.suffix}</span>}
          </div>
          {tab.activeUnderlineColor && (
            <div
              style={{
                marginTop: 1,
                height: index === selectedTabIndex ? 3 : 0,
                borderRadius: 10,
                width: '100%',
                transition: 'height 0.3s ease',
                background: tab.activeUnderlineColor
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
