import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox
} from '@mui/material';
import { objectsAreEqual } from '../../utils/arraysAreEqual';
import styles from './genricTable.module.css';
import { GenericTableProps } from './GenericTable';

const isDate = (value: any): value is Date => value instanceof Date;

function GenericTableDesktop<T extends Record<string, any>>({
  columns,
  data,
  onRowClick,
  rowKey,
  actionsColumn,
  multiSelect,
  isRowLoading
}: GenericTableProps<T>) {
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof T | ''>('');
  const [selectedRows, setSelectedRows] = React.useState<T[]>([]);

  const handleSort = (columnId: keyof T) => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  // Determine which column IDs are present in the data
  const validColumnIds = new Set<keyof T>(
    data.length > 0 ? (Object.keys(data[0]) as Array<keyof T>) : []
  );

  const sortedData = React.useMemo(() => {
    if (!orderBy || !validColumnIds.has(orderBy)) return data;

    return [...data].sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const aDate = new Date(aValue);
        const bDate = new Date(bValue);
        if (!isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) {
          return (
            (aDate.getTime() - bDate.getTime()) * (order === 'asc' ? 1 : -1)
          );
        }
        return aValue.localeCompare(bValue) * (order === 'asc' ? 1 : -1);
      } else if (typeof aValue === 'number' && typeof bValue === 'number') {
        return (aValue - bValue) * (order === 'asc' ? 1 : -1);
      } else if (isDate(aValue) && isDate(bValue)) {
        return (
          (aValue.getTime() - bValue.getTime()) * (order === 'asc' ? 1 : -1)
        );
      }
      return 0;
    });
  }, [data, order, orderBy, validColumnIds]);

  const onRowSelect = (e: any, row: T) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = e.target.checked;

      if (isSelected) {
        const newValue = [...prevSelectedRows, row];
        multiSelect?.onSelectedRowChange(newValue);
        return newValue;
      } else {
        const newValue = prevSelectedRows.filter(
          (prevSelectedRow) => !objectsAreEqual(prevSelectedRow, row)
        );
        multiSelect?.onSelectedRowChange(newValue);
        return newValue;
      }
    });
  };

  const onRowSelectAll = (e: any) => {
    setSelectedRows((prevSelectedRows) => {
      const newValue = prevSelectedRows.length === data.length ? [] : data;
      multiSelect?.onSelectedRowChange(newValue);
      return newValue;
    });
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {multiSelect?.enabled && (
              <TableCell padding="checkbox" className={styles.headerCell}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Checkbox
                    classes={{
                      checked: styles.multiSelect__checkbox
                    }}
                    checked={selectedRows.length === data.length}
                    onChange={onRowSelectAll}
                    inputProps={{
                      'aria-label': 'select row'
                    }}
                  />
                </div>
              </TableCell>
            )}
            {columns.map((column, index) => {
              const isSortable = validColumnIds.has(column.id as keyof T);

              return (
                <TableCell key={String(index)} className={styles.headerCell}>
                  {isSortable ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => handleSort(column.id as keyof T)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              );
            })}
            {actionsColumn && <TableCell className={styles.headerCell} />}

            {/* Empty header for actions column */}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row) => {
            const isLoading = isRowLoading?.(row) || false;

            return (
              <TableRow
                key={row[rowKey] as React.Key} // Use the rowKey prop here
                style={{
                  cursor: onRowClick ? 'pointer' : 'default',
                  background: isLoading ? '#f4f4f4' : 'inherit'
                }}
                className={isLoading ? styles.skeleton : ''}
                onClick={(e: any) =>
                  !isLoading && onRowClick && onRowClick(e, row)
                }
              >
                {multiSelect?.enabled && (
                  <TableCell padding="checkbox" className={styles.tableCell}>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Checkbox
                        classes={{
                          checked: styles.multiSelect__checkbox
                        }}
                        checked={
                          !!selectedRows.find((selectedRow) =>
                            objectsAreEqual(row, selectedRow)
                          )
                        }
                        onChange={(e) => onRowSelect(e, row)}
                        inputProps={{
                          'aria-label': 'select row'
                        }}
                      />
                    </div>
                  </TableCell>
                )}
                {columns.map((column) => (
                  <TableCell
                    key={String(column.id)}
                    className={styles.tableCell}
                  >
                    {column.render
                      ? column.render(row)
                      : (row[column.id] as React.ReactNode)}
                  </TableCell>
                ))}
                {actionsColumn && (
                  <TableCell className={styles.tableCell}>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      {actionsColumn(row)}
                    </div>
                  </TableCell> // Render the actions column
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default GenericTableDesktop;
