import React, {
  createContext,
  FC,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { PostFoundItemJobsRecord } from '../../types/supabase/collections';
import Api from '../../servieces/Api/Api';
import { useHeaderProgressBar } from '../HeaderProgressBarContext/ProgressBarContext';
import FindyApi from '../../sharedPacakge/findyApi/fetchFindyApi';
import { useSnackbar } from '../../sharedPacakge/components/Snackbar/SnackbarContext';
import { Database } from '../../types/supabase/supabaseDB';
import { useAuth } from '../AuthContext/AuthContext';
import { useLocationData } from '../LocationDataContext/LocationDataContext';
import Page from '../../pages/Page';
import ReactDOM from 'react-dom';
import styles from '../UploadItemLoading/uploadItemLoading.module.css';
import { UploadItemsLoading } from './UploadItemsLoading';

type PostFoundItemStates =
  | 'preview_item_posted'
  | 'image_detected_updated_item'
  | 'matchmaking_completed';

export type PostFoundItemJobsContext = {
  onPreviewItemPosted: (callback: (foundItemsIds: number[]) => void) => void;
  onImageDetectionUpdateItems: (
    callback: (foundItemsIds: number[]) => void
  ) => void;
  postFoundItem: (
    imageFile: File,
    data: Omit<PostFoundItemPayloadData, 'posted_by_user_id' | 'location_id'>
  ) => void;
  uploadingItemsAmount: number;
};

const defaultValue: PostFoundItemJobsContext = {
  onPreviewItemPosted: () => {},
  onImageDetectionUpdateItems: () => {},
  postFoundItem: () => {},
  uploadingItemsAmount: 0
};

const PostFoundItemJobsContext =
  createContext<PostFoundItemJobsContext>(defaultValue);

type FoundItemInsert = Database['public']['Tables']['found_items']['Insert'];
type GenericPostFoundItem = Omit<FoundItemInsert, 'image' | 'expiry_date'>;

export type PostFoundItemPayloadData =
  | (GenericPostFoundItem & { imageDetection: false; isMultipleItems: boolean })
  | (GenericPostFoundItem & { imageDetection: true; isMultipleItems: false });

export const PostFoundItemJobsProvider: FC<{ children: ReactNode }> = ({
  children
}) => {
  const { user } = useAuth();
  const { location } = useLocationData();
  const [subscribedToJobs, setSubscribedToJobs] = useState<Array<number>>([]);
  const [jobs, setJobs] = useState<Array<PostFoundItemJobsRecord>>([]);
  // const { addOrUpdateProgressbar } = useHeaderProgressBar();
  const { showSnackbar } = useSnackbar();
  const [uploadingItems, setUploadingItems] = useState<
    Array<{
      imageFile: File;
      data: Omit<PostFoundItemPayloadData, 'posted_by_user_id' | 'location_id'>;
    }>
  >([]);

  const onPreviewItemPostedCallbacksRef = useRef<
    ((foundItemsIds: number[]) => void) | null
  >(null) as MutableRefObject<((foundItemsIds: number[]) => void) | null>;

  const onPreviewItemPosted = (callback: (foundItemsIds: number[]) => void) => {
    onPreviewItemPostedCallbacksRef.current = callback;
  };

  const onImageDetectionUpdateItemsCallbacksRef = useRef<
    ((foundItemsIds: number[]) => void) | null
  >(null) as MutableRefObject<((foundItemsIds: number[]) => void) | null>;
  const onImageDetectionUpdateItems = (
    callback: (foundItemsIds: number[]) => void
  ) => {
    onImageDetectionUpdateItemsCallbacksRef.current = callback;
  };

  useEffect(() => {
    console.log('jobs', jobs);

    jobs.forEach((job, index) => {
      switch (job.state) {
        case 'image_detected_updated_item':
          if (onImageDetectionUpdateItemsCallbacksRef.current)
            onImageDetectionUpdateItemsCallbacksRef.current(job.found_item_ids);
          break;
        case 'matchmaking_completed':
          setJobs((prev) => prev.filter((j) => j.id !== job.id));
          break;
      }
    });

    if (jobs.length === 0) {
      return;
    }

    const unsubscribe = Api.subscribe.foundItemJobs(
      jobs.map((job) => job.id),
      (newOrUpdatedJob) => {
        setJobs((prevJobs) => {
          const existingJobIndex = prevJobs.findIndex(
            (j) => j.id === newOrUpdatedJob.id
          );

          if (existingJobIndex !== -1) {
            // Replace the existing job in the array
            const updatedJobs = [...prevJobs];
            updatedJobs[existingJobIndex] = newOrUpdatedJob;
            return updatedJobs;
          }

          // If job doesn't exist, add it to the array
          return [...prevJobs, newOrUpdatedJob];
        });
      }
    );

    return () => {
      unsubscribe();
    };
  }, [jobs]);

  const postFoundItem = (
    imageFile: File,
    data: Omit<PostFoundItemPayloadData, 'posted_by_user_id' | 'location_id'>
  ) => {
    if (!location || !user) return;

    setUploadingItems((prev) => [...prev, { imageFile, data }]);
    const formData = new FormData();
    formData.append('image', imageFile);

    const postFoundItemPayload: PostFoundItemPayloadData = {
      ...data,
      posted_by_user_id: user.id,
      location_id: location.id
    } as PostFoundItemPayloadData;

    formData.append('data', JSON.stringify(postFoundItemPayload));

    const randomId = new Date().getTime();
    // addOrUpdateProgressbar(randomId, 0);

    FindyApi.post('found-item/frontend-proxy', formData)
      .then(({ job }: { job: PostFoundItemJobsRecord }) => {
        // setSubscribedToJobs((prev) => [...prev, job.id]);
        setJobs((prev) => [...prev, job]);
        // addOrUpdateProgressbar(randomId, 100);

        setUploadingItems((prev) => prev.slice(1));
        if (onImageDetectionUpdateItemsCallbacksRef.current)
          onImageDetectionUpdateItemsCallbacksRef.current(job.found_item_ids);

        if (job.found_item_ids.length > 0) {
          showSnackbar(
            <>
              Billede uploadet{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href={Page.l.viewFoundItem(location.id, job.found_item_ids[0])}
              >
                her
              </a>
            </>
          );
        }
      })
      .catch(() => {
        showSnackbar('Der skete en fejl. genstand blev ikke oprettet', 'error');
        // addOrUpdateProgressbar(randomId, 100);
      });
  };

  const portal = document.getElementById('posting-items-root');
  if (!portal) {
    console.warn("Snackbar can't open, there is no snackbar root in the HTML");
    return null;
  }

  return (
    <PostFoundItemJobsContext.Provider
      value={{
        onImageDetectionUpdateItems,
        onPreviewItemPosted,
        postFoundItem,
        uploadingItemsAmount: uploadingItems.length
      }}
    >
      {children}
      {ReactDOM.createPortal(
        <UploadItemsLoading uploadingItems={uploadingItems} />,
        portal
      )}
    </PostFoundItemJobsContext.Provider>
  );
};

export const usePostFoundItemJobs = (): PostFoundItemJobsContext => {
  const context = useContext(PostFoundItemJobsContext);
  if (!context) {
    throw new Error(
      'useLocationData must be used within an LocationDataProvider'
    );
  }
  return context;
};
